<template>
  <!--begin::Step 2-->
  <div
    v-if="itemStore.platforms?.length > 0"
    data-kt-stepper-element="content"
  >
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      <!-- Опция поиска по названию -->
      <div
        v-if="isUserTypePremium"
        class="d-flex flex-column mb-7 fv-row"
      >
        <div class="d-flex flex-stack">
          <!-- Поле для ввода названия поиска -->
          <div
            :class="[
              'form-floating w-100 me-4 mb-n1',
              isSearchByTitle ? '' : 'd-none'
            ]"
            style="margin-top: -2px"
          >
            <Field
              type="text"
              id="nameSearchValue"
              class="form-control"
              :placeholder="$t('pages.items.modal.placeholder.title')"
              name="title"
              v-model="localItemData.title"
            />
            <label for="nameSearchValue">{{ $t('pages.items.modal.label.byTitle') }}</label>
          </div>

          <div v-if="!isSearchByTitle" class="me-5">
            <label class="form-label">{{ $t('pages.items.modal.label.byTitle') }}</label>              
            <div class="text-muted fs-7">
              {{ $t('pages.items.modal.subLabel.byTitle') }}
            </div>
          </div>
          
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="isSearchByTitle"
              @change="toggleByTitle"
              :disabled="isEmpty(localItemData.title)"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.byTitle') }}
            </span>
          </label>
        </div>
        
        <ErrorMessage
          v-if="isSearchByTitle"
          class="fv-plugins-message-container invalid-feedback"
          name="title"
        />
      </div>

      <div
        v-for="platform in itemStore.platforms"
        :key="platform.id"
        class="d-flex flex-column mb-5 mb-md-7 fv-row"
      >
        <div 
          v-if="localItemData.platform?.[platform.id]?.links"
          v-for="(link, linkID) in localItemData.platform[platform.id].links" 
          :key="`platform_${platform.id}_${linkID}`" 
          class="d-flex flex-column mb-2"
        >
          <div class="d-flex align-items-center gap-2">
            <!-- Кнопка клика по ссылке -->
            <button
              type="button"
              :class="[
                'btn btn-icon flex-shrink-0',
                isLinkError(platform.id, linkID) ? 'btn-light-warning' :
                !isLinkValid(platform.id, linkID) ? 'btn-light-secondary' : 'btn-light-success'
              ]"
              @click="clickLink(platform.id, linkID)"
              :disabled="!isLinkValid(platform.id, linkID)"
            >
              <KTIcon icon-name="exit-right-corner" icon-class="fs-3" />
            </button>

            <!-- Поле для ввода ссылки -->
            <Field
              type="text"
              :class="[
                'form-control flex-grow-1',
                { 'border-warning': isLinkError(platform.id, linkID) }
              ]"
              :placeholder="platform.url"
              :name="`platform.platform_${platform.id}.key_${linkID}`"
              v-model="localItemData.platform[platform.id].links[linkID]"
              :disabled="isChackByTitle"
            />

            <!-- Переключатель активности ссылки -->
            <label
              v-if="isSearchByTitle"
              class="form-check form-switch form-check-custom form-check-success form-check-solid flex-shrink-0 ms-2"
            >
              <input 
                class="form-check-input"
                type="checkbox"
                :checked="isLinkStatus(platform.id, linkID)"
                @change="toggleLinkState(platform, linkID)"
              />
              <span class="form-check-label fw-semibold text-gray-500">
                {{ $t('pages.items.modal.other.byTitle') }}
              </span>
            </label>
          
            <!-- Кнопка для добавления новой ссылки -->
            <button
              v-else-if="Object.keys(localItemData.platform[platform.id].links).indexOf(linkID) === 0"
              type="button"
              class="btn btn-icon btn-light-primary flex-shrink-0"
              @click="addLink(platform.id)"
              :disabled="(
                localItemData.platform[platform.id] && 
                Object.keys(localItemData.platform[platform.id].links).length >= (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)) || 
                isSearchByTitle"
            >
              <KTIcon icon-name="plus" icon-class="fs-3" />
            </button>

            <!-- Кнопка для удаления ссылки -->
            <button
              v-else
              type="button"
              class="btn btn-icon btn-light-danger flex-shrink-0"
              @click="removeLink(platform.id, linkID)"
            >
              <KTIcon icon-name="trash" icon-class="fs-3" />
            </button>
          </div>
          
          <div
            v-if="isLinkError(platform.id, linkID)"
            class="fv-plugins-message-container invalid-feedback d-block mt-1"
          >
            {{ $t('pages.items.modal.error.link', { count: localItemData.platform[platform.id].fail?.[linkID] }) }}
          </div>

          <!-- Сообщение об ошибке валидации -->
          <ErrorMessage 
            class="fv-plugins-message-container invalid-feedback d-block mt-1"
            :key="`platform_${platform.id}_${linkID}`" 
            :name="`platform.platform_${platform.id}.key_${linkID}`" 
          />
        </div>

        <!-- Описание и ссылка на платформу -->
        <div class="text-muted fs-7">
          <span v-html="translatedLink(platform)"></span>
        </div>
      </div>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 2-->
</template>

<script lang="ts">
import { defineComponent, watch, computed, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { 
  generateLink,
  generateSearchLink, 
  cleanLinkInput,
  isUrlMatchingPlatform,
  initPlatformLinks,
  type ItemData,
  type Platform
} from "@/utils/helpers/itemHelpers";

export default defineComponent({
  name: "item-modal-step2",
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    localItemData: {
      type: Object as () => ItemData,
      required: true
    },
    itemStore: {
      type: Object as () => ItemData,
      required: true
    },
    isUserTypePremium: {
      type: Boolean,
      required: true
    },
  },
  setup(props) {
    const { t } = useI18n();
    const userStore = useAuthStore();
    
    // Инициализация объекта для сохраненных ссылок
    const platformSavedLinks = ref<{
      searchTitle: ItemData["platform"];  // Ссылки для searchTitle
      searchLink: ItemData["platform"];   // Ссылки для searchLink
    }>({
      searchTitle: {},  // Инициализация пустого объекта для searchTitle
      searchLink: {}  // Инициализация пустого объекта для searchLink
    });

    const isSearchByTitle = computed(() => props.localItemData.search_by_title === '1');
    const isChackByTitle = computed(() => {
      return isSearchByTitle.value && !isEmpty(props.localItemData.title) && props.isUserTypePremium;
    });
    
    // Проверяет, является ли ссылка для заданной платформы и идентификатора ссылки валидной.
    const isLinkValid = computed(() => (platformID: string, linkID: string) => {
      if (!props.localItemData.platform) return false;
      const url = props.localItemData.platform[platformID]?.links[linkID];
      const platform = props.itemStore.platforms?.find(p => p.id === platformID);
    
      if (!url || !platform) return false;
    
      const finalUrl = generateLink(platform, url);
      return isUrlMatchingPlatform(finalUrl, platform);
    });

    // Добавляет новую ссылку для указанной платформы.
    const addLink = (platformID: string) => {
      if (!platformID) return;

      const existingLinks = Object.keys(props.localItemData.platform[platformID].links);

      if (!existingLinks.length) {
        props.localItemData.platform[platformID].links = {'n0': ''};
      } else if (existingLinks.length < (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)) {
        const newLinkID = generateNewLinkID(existingLinks);
        props.localItemData.platform[platformID].links[newLinkID] = '';
      }
    };

    // Генерирует новый идентификатор для ссылки на основе существующих идентификаторов.
    const generateNewLinkID = (existingLinks: string[]) => {
      const maxIndex = existingLinks.reduce((max, linkID) => {
        const match = linkID.match(/^n(\d+)$/);
        return match ? Math.max(max, parseInt(match[1], 10)) : max;
      }, -1);

      return `n${maxIndex + 1}`;
    };

    // Удаляет ссылку с указанным идентификатором.
    const removeLink = (platformID: string, linkID: string) => {
      if (Object.prototype.hasOwnProperty.call(props.localItemData.platform[platformID].links, linkID)) {
        delete props.localItemData.platform[platformID].links[linkID];
      }
    };

    // Открывает ссылку в новом окне.
    const clickLink = (platformID: string, linkID: string) => {
      const url = props.localItemData.platform[platformID]?.links?.[linkID];
      const platform = props.itemStore.platforms?.find((p) => p.id === platformID);

      if (platform && url) {
        const finalUrl = generateLink(platform, url);
        window.open(finalUrl, '_blank');
      }
    };

    // Создает HTML-ссылку для указанной платформы.
    const platformLink = (platform) => {
      return `<a href="${platform.url}" aria-label="${platform.title}" target="_blank">${platform.title}</a>`;
    };

    // Форматирует строку перевода с вставленной HTML-ссылкой.
    const translatedLink = (platform) => {
      return t('pages.items.modal.subLabel.link', { platform: platformLink(platform) });
    };
    
    // Проверяет, является ли строка пустой или содержит только пробелы./
    const isEmpty = (value: string | null | undefined): boolean => {
      return !value || value.trim() === "";
    }
    
    // Переключает состояние byTitle и изменяет ссылки на платформе.
    const toggleByTitle = () => {
      // Переключаем состояние byTitle
      props.localItemData.search_by_title = isSearchByTitle.value ? '0' : '1';
      
      // Прерываем, если нет названия товара
      if (isEmpty(props.localItemData.title)) {
        return;
      }
      
      saveOriginalLinks(true); // Сохраняем оригинальные ссылки
      changePlatformsLinks(props.localItemData.title, true);
    }

    // Восстанавливает сохраненные ссылки на платформе из platformSavedLinks.
    const restorePlatformsLinks = (search: string) => {
      // Проверяем, является ли платформенный объект пустым
      if (Object.keys(platformSavedLinks.value[search]).length === 0) {
        // Если пустой, инициализируем с помощью initPlatformLinks
        props.localItemData.platform = initPlatformLinks(props.itemStore.platforms);
      } else {
        // Иначе сохраняем текущие платформенные данные
        props.localItemData.platform = JSON.parse(JSON.stringify(platformSavedLinks.value[search]));
      }
    };
    
    // Сохраняет исходные ссылки для текущего товара один раз, если они еще не сохранены.
    const saveOriginalLinks = (ticker: boolean = false) => {
      const searchKey = isSearchByTitle.value 
        ? (ticker ? 'searchLink' : 'searchTitle') 
        : (ticker ? 'searchTitle' : 'searchLink');
  
      // Проверяем, является ли платформенный объект пустым
      platformSavedLinks.value[searchKey] = Object.keys(props.localItemData.platform).length === 0
        ? initPlatformLinks(props.itemStore.platforms) // Если пустой, инициализируем с помощью initPlatformLinks
        : JSON.parse(JSON.stringify(props.localItemData.platform)); // Сохраняем текущие платформенные данные
    };

    /**
     * Проверяет, отличается ли новая сгенерированная ссылка от первой текущей ссылки
     * для данной платформы, корректно обрабатывая URL-кодирование.
     */
    const isNewLinkDifferent = (platformID: string, newLink: string): boolean => {
      const currentLink = Object.values(props.localItemData.platform?.[platformID]?.links || {})[0];
      
      // Декодируем URL для корректного сравнения
      const decodedCurrentLink = currentLink ? decodeURIComponent(currentLink) : null;
      const decodedNewLink = decodeURIComponent(newLink);
      
      return decodedCurrentLink !== decodedNewLink.trim();
    };
    
    /**
     * Генерирует и устанавливает новую ссылку для платформы, если она отличается от текущей.
     * - Если новая ссылка совпадает с одной из сохраненных оригинальных ссылок, восстанавливает соответствующий ключ.
     * - Если совпадения нет, устанавливает новую ссылку с ключом `'n0'`.
     * - Если ссылка не изменилась или новая ссылка отсутствует, восстанавливает только совпадающие ключи из оригинальных ссылок.
     */
    const updateLinkIfDifferent = (platformId: string, newLink: string, toogle: boolean) => {
      const savedLinksGroup = platformSavedLinks.value[isSearchByTitle.value ? 'searchTitle' : 'searchLink'];
      
      const originalLinks = savedLinksGroup[platformId]?.links || {};
      const originalStatus = savedLinksGroup[platformId]?.status || {};
      const localItemStatus = props.localItemData.platform[platformId]?.status || {};

      if (originalLinks && Object.keys(originalLinks).length > 0 && !toogle && !isSearchByTitle.value) {
        props.localItemData.platform[platformId].links = { ...originalLinks };
      }
      // Проверяем, отличается ли новая ссылка от текущей
      else if (isNewLinkDifferent(platformId, newLink)) {
        const originalLinkKey = Object.keys(originalLinks).find(
          key => originalLinks[key] && decodeURIComponent(originalLinks[key]) === decodeURIComponent(newLink).trim()
        );

        if (
          originalLinkKey || 
          props.localItemData.id === null || 
          props.localItemData?.isLinks !== '1' ||
          Object.keys(localItemStatus).length > 0
        ) {
          props.localItemData.platform[platformId].links = originalLinkKey
            ? { [originalLinkKey]: newLink }
            : { 'n0': newLink };

          props.localItemData.platform[platformId].status = originalLinkKey
            ? { [originalLinkKey]: originalStatus[originalLinkKey] }
            : { 'n0': localItemStatus['n0'] ?? '1' };
        }
      }
    };
        
    /**
     * Основная функция для обновления ссылок платформы на основе шаблонов и названия товара.
     * При необходимости восстанавливает оригинальные ссылки.
     */
    const changePlatformsLinks = (title: string, toogle: boolean, platform: Platform) => {
      if (isSearchByTitle.value) {        
        if (platform?.id) {
          // Обновляем ссылку для платформы platformID
          const newLink = generateSearchLink(platform, title);
          updateLinkIfDifferent(platform.id, newLink, toogle); // Проверяем и обновляем ссылку, если нужно
        } else {
          // Обновляем ссылки для каждой платформы
          props.itemStore.platforms.forEach((platform: Platform) => {
            const newLink = generateSearchLink(platform, title);
            updateLinkIfDifferent(platform.id, newLink, toogle); // Проверяем и обновляем ссылку, если нужно
          });
        }
        return;
      }

      // Восстанавливаем оригинальные ссылки, если byTitle отключен
      restorePlatformsLinks('searchLink');
    };
    
    const isLinkError = (platformID: string, linkID: string) => {
      const original = props.localItemData.platform[platformID];

      if (
        original.status?.[linkID] === '0' && 
        original.fail?.[linkID] > 25
      ) {
        return true;
      }
      
      return false;
    };
    
    const isLinkStatus = (platformID: string, linkID: string) => {
      const original = props.localItemData.platform[platformID];

      if (        
        isSearchByTitle.value && 
        original.links?.[linkID] && 
        original.status?.[linkID] === '1'
      ) {
        return true;
      }
      
      original.links[linkID] = '';
      return false;
    };
    
    // Переключает состояние активности для конкретной ссылки на платформе.
    const toggleLinkState = (platform: Platform, linkID: string) => {
      const original = props.localItemData.platform[platform.id];

      // Инициализируем статус, если отсутствует, и переключаем значение
      original.status = original.status || {};
      original.status[linkID] = original.status[linkID] === '1' ? '0' : '1';
      
      // Обновляет состояние активных ссылок.
      if (original.status[linkID] === '1') {
        handleTitleChange(props.localItemData.title, platform);
      } else if (original.links[linkID]) {
        original.links[linkID] = '';
      }
      
      saveOriginalLinks(false); // Сохраняем оригинальные ссылки
    };
    
    /**
     * Обрабатывает изменение заголовка (title), удаляя начальные пробелы и обновляя ссылки, если это необходимо.
     * Эта функция выполняет следующие шаги:
     * 1. Убирает начальные пробелы в строке.
     * 2. Если заголовок изменился, обновляет его в данных компонента.
     * 3. Если включен поиск по заголовку (byTitle), обновляет ссылки или восстанавливает их, если заголовок пустой.
     */
    const handleTitleChange = (newTitle: string, platform: Platform = {}) => {
      // Убираем пробелы в начале строки
      const trimmedTitle = newTitle?.replace(/^\s+/, "") || "";
      
      // Если измененный title отличается, обновляем его, удаляя начальные пробелы
      if (trimmedTitle !== newTitle) {
        props.localItemData.title = trimmedTitle;
      }
      
      // Обновляем ссылки, если byTitle включен и title не пустой
      if (isSearchByTitle.value) {
        if (!isEmpty(trimmedTitle)) {
          changePlatformsLinks(trimmedTitle, false, platform);
        } else {
          // Восстанавливаем оригинальные ссылки
          restorePlatformsLinks('searchTitle');
        }
      }
    };
    
    // Наблюдает за изменением заголовка (title) и обновляет ссылки при включенном byTitle.
    watch(() => props.localItemData.title, (newTitle) => {
      handleTitleChange(newTitle);
    });
    
    // Наблюдает за изменением ID и сохраняет данные при изменении.
    watch(() => props.localItemData.id, (newID, oldID) => {
      if (newID === null) {
        platformSavedLinks.value = { searchTitle: {}, searchLink: {} };
      }
    
      if (newID !== oldID) {
        saveOriginalLinks(false); // Сохраняем оригинальные ссылки
      }
    });
    
    // Наблюдает за изменением структуры ссылок платформы и применяет очистку данных.
    watch(() => props.localItemData.platform, (newLinks) => {
      if (newLinks && typeof newLinks === 'object') {
        Object.keys(newLinks).forEach(platformID => {
          const platformData = newLinks[platformID];
          if (platformData?.links) {
            Object.entries(platformData.links).forEach(([linkID, link]) => {
              if (link) props.localItemData.platform[platformID].links[linkID] = cleanLinkInput(link);
            });
          }
        });
      }
    }, { deep: true });

    return {
      userStore,
      isLinkValid,
      addLink,
      removeLink,
      clickLink,
      translatedLink,
      toggleByTitle,
      toggleLinkState,
      isEmpty,
      isSearchByTitle,
      isChackByTitle,
      isLinkStatus,
      isLinkError,
    };
  },
});
</script>
