<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <!-- Заголовок и панель поиска -->
      <div class="card-title w-100 me-0">
        <!-- Поле поиска -->
        <div class="d-flex align-items-center position-relative my-1 w-100">
          <KTIcon
            icon-name="magnifier"
            icon-class="fs-2 position-absolute ms-4"
          />
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            :placeholder="$t('search')"
            class="form-control form-control-solid mw-500px mw-sm-250px ps-12"
          />
        </div>
      </div>
  
      <!-- Панель инструментов карточки -->
      <div class="card-toolbar">
        <div
          v-if="selectedIds.length === 0"
          class="d-flex justify-content-end"
        >
          <!-- Кнопка для экспорта -->
<!--
          <button
            type="button"
            class="btn btn-sm btn-light-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_items_export_modal"
          >
            <KTIcon icon-name="exit-up" icon-class="fs-2" />
            {{ $t('export') }}
          </button>
-->
        </div>
      
        <!-- Панель действий при выбранных элементах -->
        <div v-else class="d-flex justify-content-end align-items-center">
          <!-- Показ количества выбранных элементов -->
          <div class="mx-5 text-nowrap">
            <EndingFormatter
              :number="selectedIds.length"
              :showNumber="true"
              :single="$t('pages.items.formatter.single')"
              :few="$t('pages.items.formatter.few')"
              :many="$t('pages.items.formatter.many')"
            />
          </div>
          <!-- Кнопка удаления -->
          <button
            type="button"
            ref="deleteButton"
            class="btn btn-sm btn-danger text-nowrap"
            @click="deleteFewItems()"
          >
            <span class="indicator-label"> {{ $t('buttons.remove') }} </span>
            <span class="indicator-progress">
              {{ $t('buttons.wait') }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <!-- Таблица данных -->
    <div class="card-body pt-0 pb-5">
      <Datatable
        v-if="!isLoading && sortedFilteredItems.length > 0"
        class="table align-middle table-row-dashed gy-3"
        :header="tableHeader"
        :data="sortedFilteredItems"
        :items-per-page="itemsPerPage"
        :items-per-page-dropdown-enabled="itemsPerPageDropdown"
        :preload-count="10"
        :checkbox-enabled="true"
        :emptyTableText="$t('dataTable.emptyTable')"
        @on-sort="handleSort"
        @on-items-per-page-change="handleItemsPerPage"
        @on-items-select="onItemSelect"
      >
        <!-- Слоты для кастомного отображения колонок -->
        <template v-slot:title="{ row: item }">
          <!-- Контент заголовка с изображением и заголовком -->
          <div class="d-flex align-items-center min-w-200px">
            <a
              v-if="item.image || item.title"
              :href="item.link"
              :aria-label="item.title"
              class="symbol symbol-50px"
              target="_blank"
            >
              <span 
                class="symbol-label" 
                :style="`background-image: url('${item.image ? item.image : ''}');`">
                {{ item.image ? '' : item.title[0] }}
              </span>
            </a>
            <span v-else class="symbol symbol-50px">
              <div class="symbol-label">
                <i class="ki-outline ki-picture fs-1"></i>
              </div>
            </span>
            <div class="d-flex flex-column ms-5">
              <a
                v-if="item.title"
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                class="fw-bold text-gray-800 text-hover-primary fs-5 mb-1"
                :title="item.title"
              >
                {{ truncateTitle(item.title) }}
              </a>
              <div
                v-else
                class="fw-semibold text-gray-600 fs-5 mb-1"
              >
                {{ $t('pages.searchId.itemRemoved') }}
              </div>
              
              <div class="d-flex align-items-center text-muted fs-7">
                <template v-if="item.platformId">
                  {{ getPlatformTitle(item.platformId) }}
                </template>
                
                <template v-if="item.brand">
                  <span
                    v-if="item.platformId"
                    class="bullet bullet-dot mx-2"
                  ></span>
                  <span>{{ item.brand }}</span>
                </template>
                
                <i
                  v-if="item.discounted"
                  class="ki-duotone ki-discount ms-2 fs-3 text-danger"
                  v-tooltip data-bs-custom-class="tooltip-inverse"
                  :title="$t('pages.items.discounted')"
                >
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
              </div>
            </div>
          </div>
        </template>
        
        <!-- Остальные слоты для отображения значений -->
        <template v-slot:rating="{ row: item }">
          <RatingStars
            v-if="item.rating > 0"
            :rating="Number(item.rating)"
            mode="short"
          />
          <div v-else class="text-gray-400">–</div>
        </template>
      
        <template v-slot:price="{ row: item }">
          <a
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
            class="fw-bold text-gray-700 text-hover-primary fs-6"
          >
            <PriceFormatter :price="item.price" class="text-nowrap" />
          </a>
        </template>
      
        <template v-slot:ai_score="{ row: item }">
          <div class="text-muted fw-semibold">
            {{ Math.round(item.ai_score * 100) }}%
          </div>
        </template>
      
        <template v-slot:timestamp_x="{ row: item }">
          <div class="d-flex flex-column">
            <DateFormatter 
              :date="item.timestamp_x" 
              :format="'simpleDate'"
              class="text-nowrap fw-semibold text-gray-800 fs-7"
            />
          </div>
        </template>
      </Datatable>
      
      <!-- Заглушка при отсутствии данных -->
      <Placeholder
        v-else
        :length="5"
        :table-header="tableHeader"
        :items-per-page="itemsPerPage"
      />
    </div>
  </div>
  
  <OfferUniqueWordsModal
    :new-unique-words="newUniqueWords"
    :now-unique-words="nowUniqueWords"
    :group-words="groupWords"
    @remove-items-modal="handleIsConfirmed"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { useItemStore } from "@/stores/item";
import { usePriceChartStore, type ItemsData } from "@/stores/priceChart";
import Swal from "sweetalert2/dist/sweetalert2.js";
import arraySort from 'array-sort';
import { truncateTitle, dataStoreSearchs, uniqueWordsInDataChartNotInItems } from "@/utils/helpers/listHelpers";
import { addToast } from '@/utils/toastManager';
import { showModal } from "@/core/helpers/modal";

import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import RatingStars from '@/components/RatingStars.vue';
import Placeholder from '@/components/placeholder/ItemsList.vue';
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import OfferUniqueWordsModal from "@/components/modals/general/OfferUniqueWordsModal.vue";

interface Payload {
  searchId: number;
  ids: number[];
  uniqueWords?: {
    required_words?: string;
    excluded_words?: string;
  };
}

export default defineComponent({
  name: "search-id-analytics-list",
  components: {
    Datatable,
    PriceFormatter,
    DateFormatter,
    RatingStars,
    Placeholder,
    EndingFormatter,
    OfferUniqueWordsModal,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['items-remove'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const bodyStore = useConfigStore();
    const itemStore = useItemStore();
    const dataChart = usePriceChartStore();

    const sortState = ref({ label: null, order: 'asc' });
    const deleteButton = ref<null | HTMLButtonElement>(null);
    const selectedIds = ref<Array<number>>([]);
    const search = ref<string>("");
    const newUniqueWords = ref({});
    const nowUniqueWords = ref({});
    const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));

    // Заголовки таблицы
    const tableHeader = ref([
      {
        columnName: t('pages.searchId.layoutList.titleTable.items'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: "min-w-225px",
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.rating'),
        columnLabel: "rating",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.price'),
        columnLabel: "price",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.ai_score'),
        columnLabel: "ai_score",
        columnClass: "min-w-125px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.date'),
        columnLabel: "timestamp_x",
        columnClass: "min-w-125px",
        sortEnabled: true,
      },
    ]);
    
    const searchId = computed(() => Number(route.params.id) || 0);
    
    const groupWords = computed(() => {
      const search = itemStore.searchs?.find(item => Number(item.id) === searchId?.value);
      const group = search && itemStore.groups?.find(item => item.id === search.group);
    
      return {
        required_words: group?.required_words,
        excluded_words: group?.excluded_words,
      };
    });

    // Обработчик сортировки
    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const itemsPerPageDropdown = computed(() => {
      return sortedFilteredItems.value?.length >= 10;
    });

    // Фильтр и сортировка данных
    const sortedFilteredItems = computed<Array<ItemsData>>(() => {
      // Копируем массив перед сортировкой
      let items = dataChart.items?.slice() || [];
    
      // Применяем фильтрацию по строке поиска
      if (search.value) {
        items = items.filter(item => searchingFunc(item, search.value ?? ''));
      }
      
      // Применяем сортировку, если указаны параметры сортировки
      if (sortState.value?.label && items.length > 0) {
        const reverse = sortState.value.order === 'desc';
        const firstItem = items[0][sortState.value.label];
        const isNumeric = !isNaN(parseFloat(firstItem)) && isFinite(firstItem);
    
        if (isNumeric) {
          return arraySort(items, (a, b) => {
            const aValue = parseFloat(a[sortState.value.label]);
            const bValue = parseFloat(b[sortState.value.label]);
            return reverse ? bValue - aValue : aValue - bValue;
          });
        } else {
          return arraySort(items, sortState.value.label, { reverse });
        }
      }
    
      return items;
    });

    // Получение названия платформы
    const getPlatformTitle = (platformId) => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return platform ? platform.title : '';
    };
    
    const onItemSelect = (selectedItems: Array<number>) => {
      selectedIds.value = selectedItems;
    };
        
    // Вызов для фильтрации, автоматически запустят пересчет `sortedFilteredItems`
    const searchItems = () => {
      search.value = search.value.trim();
    };
    
    // Функция поиска по элементам
    const searchingFunc = (obj: ItemsData, value: string): boolean => {
      // Проверка на пустую строку или null/undefined значение
      if (!value) return false;
    
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && typeof obj[key] === "string") {
          if (obj[key].toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    };
    
    const deleteFewItems = async() => {
      if (!deleteButton.value) {
        return;
      }
      
      try {
        deleteButton.value!.disabled = true;
        deleteButton.value?.setAttribute("data-kt-indicator", "on");
        
        await Promise.all([
          itemStore.searchs.length > 0 ? Promise.resolve() : itemStore.fetchSearchPartData({ id: searchId.value }),
          itemStore.items.some(item => item.parentId === searchId.value) 
            ? Promise.resolve() 
            : itemStore.fetchItemData({ id: searchId.value })
        ]);
                
        if (itemStore.searchs.length > 0 && itemStore.items.length > 0) {
          
          const dataStoreById = dataStoreSearchs(itemStore.searchs, searchId.value);
          
          nowUniqueWords.value = {
            'required_words': dataStoreById['required_words'],
            'excluded_words': dataStoreById['excluded_words'],
          };
          
          newUniqueWords.value = uniqueWordsInDataChartNotInItems(
            dataChart.items,
            selectedIds.value,
            itemStore,
            searchId.value
          );
        }
        
        if (
          newUniqueWords.value['required_words']?.length > 0 ||
          newUniqueWords.value['excluded_words']?.length > 0
        ) {
          const modalElement = document.getElementById('modal_offer_unique_words');
          // Ожидаем закрытия модального окна
          await new Promise<void>((resolve) => {
            const handleModalClose = () => {
              modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
              resolve();
            };
            modalElement.addEventListener('hidden.bs.modal', handleModalClose);
            showModal(modalElement);
          });
        } else {
          await Swal.fire({
            text: t('messages.text.warning.deleteAnalyticsItems'),
            icon: 'warning',
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t('messages.button.delete'),
            cancelButtonText: t('messages.button.noCancel'),
            heightAuto: false,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-secondary',
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              await handleIsConfirmed();              
            }
          });
        }
      } catch (error) {
        handleError(error);
      } finally {
        if (deleteButton.value) {
          deleteButton.value.removeAttribute("data-kt-indicator");
          deleteButton.value.disabled = false;
        }
      }
    };
    
    const deleteItem = (id: number) => {
      const index = dataChart.items?.findIndex(item => item.id === id);
      if (index !== -1) {
        dataChart.items?.splice(index, 1);
      }
    };
    
    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      selectedIds.value.forEach((item) => {
        deleteItem(item);
      });
      selectedIds.value.length = 0;

      addToast(
        t('messages.any.success'),
        t(`messages.text.success.deleteAnalyticsItems`),
        'success'
      );
    }

    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        dataChart.errors = [];
      });
    }
    
    const handleIsConfirmed = async (required_words?: string, excluded_words?: string, modalWords?: boolean) => {
      const payload: Payload = {
        searchId: searchId.value,
        ids: selectedIds.value,
      };
      
      // Добавляем uniqueWords, если required_words или excluded_words не пусты, либо modalWords === true
      if (required_words?.trim() || excluded_words?.trim() || modalWords) {
        payload.uniqueWords = {
          required_words: required_words?.trim() || "",
          excluded_words: excluded_words?.trim() || "",
        };
      }
    
      await dataChart.deleteItems(payload);
      emit('items-remove', searchId.value);
      
      if (!Object.keys(dataChart.errors).length) {
        handleSuccess();
      } else {
        handleError(dataChart.errors);
      }
    };
    
    const handleItemsPerPage = (val) => {
      bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
    };

    return {
      tableHeader,
      itemsPerPageDropdown,
      sortedFilteredItems,
      getPlatformTitle,
      handleSort,
      itemsPerPage,
      search,
      searchItems,
      onItemSelect,
      selectedIds,
      deleteFewItems,
      deleteButton,
      newUniqueWords,
      nowUniqueWords,
      handleIsConfirmed,
      handleItemsPerPage,
      truncateTitle,
      groupWords,
    };
  },
});
</script>