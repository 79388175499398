<template>
  <!--begin::Step 3-->
  <div data-kt-stepper-element="content">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      
      <!-- Поле для обязательных слов -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="d-flex align-items-center form-label">
          {{ $t('pages.items.modal.label.requiredWords') }}
          <KTIcon 
            icon-name="tablet-ok" 
            icon-class="fs-2 text-gray-600 ms-2"
          />
        </label>
        
        <Tagify
          mode="input"
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.requiredWords')"
          name="required_words"
          v-model="localItemData.required_words"
          :temp-words="getGroupWords(localItemData.group, 'required_words')"
        />

        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.requiredWords') }}
          {{
            getGroupWords(localItemData.group, 'required_words') 
              ? $t('pages.items.modal.subLabel.groupWords', { group: getGroupTitle(localItemData.group) }) 
              : '' 
          }}
        </div>
      </div>

      <!-- Поле для исключенных слов -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="d-flex align-items-center form-label">
          {{ $t('pages.items.modal.label.excludedWords') }}
          <KTIcon 
            icon-name="tablet-delete" 
            icon-class="fs-2 text-gray-600 ms-2"
          />
        </label>
        
        <Tagify
          mode="input"
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.excludedWords')"
          name="excluded_words"
          v-model="localItemData.excluded_words"
          :temp-words="getGroupWords(localItemData.group, 'excluded_words')"
        />
        
        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.excludedWords') }}
          {{
            getGroupWords(localItemData.group, 'excluded_words') 
              ? $t('pages.items.modal.subLabel.groupWords', { group: getGroupTitle(localItemData.group) }) 
              : '' 
          }}
        </div>
      </div>

      <!-- Поле для AI фильтра -->
      <div class="d-flex flex-column my-5 mb-md-7 fv-row">
        <div class="d-flex flex-stack my-2">
          <label class="d-flex align-items-center form-label text-nowrap mb-0 me-2">
            {{ $t('modal.label.ai_score') }}
            <KTIcon 
              icon-name="technology-4" 
              icon-class="fs-2 text-gray-600 ms-2"
            />
          </label>
          
          <!-- Ползунок для AI фильтра -->
          <div class="w-50 mx-5">
            <div id="ai_filter_slider" class="noUi-sm"></div>
          </div>
          
          <!-- Переключатель для включения/отключения AI фильтра -->
          <label class="form-check form-switch form-check-custom form-check-solid ms-5">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.ai_status == 1"
              @change="toggleAI"
            />
            <span class="form-check-label fw-semibold text-gray-500 d-none d-md-block">
              {{ $t('modal.label.active') }}
            </span>
          </label>
        </div>

        <!-- Текстовое описание AI фильтра с текущим значением -->
        <div class="text-muted fs-7">
          {{ $t('modal.subLabel.ai_filter') }}
        </div>
      </div>
      
      <!-- Фильтр Оригинальных товаров -->
      <div class="d-flex flex-column mb-5 mb-md-7 fv-row">
        <div class="d-flex flex-stack my-2">
          <div class="me-5">
            <label class="d-flex align-items-center form-label">
              {{ $t('modal.label.original') }}
              <KTIcon 
                icon-name="verify" 
                icon-class="fs-2 text-gray-600 ms-2"
              />
            </label>              
            <div class="text-muted fs-7">
              {{ $t('modal.subLabel.original') }}
            </div>
          </div>
        
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.isOriginal == 1"
              @change="toggleOriginal"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.byTitle') }}
            </span>
          </label>
        </div>
      </div>
      
      <!-- Фильтр Уцененных товаров -->
      <div class="d-flex flex-column mb-5 mb-md-7 fv-row">
        <div class="d-flex flex-stack my-2">
          <div class="me-5">
            <label class="d-flex align-items-center form-label">
              {{ $t('modal.label.discounted') }}
              <KTIcon 
                icon-name="discount" 
                icon-class="fs-2 text-gray-600 ms-2"
              />
            </label>              
            <div class="text-muted fs-7">
              {{ $t('modal.subLabel.discounted') }}
            </div>
          </div>
        
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.isDiscounted == 1"
              @change="toggleDiscounted"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.byTitle') }}
            </span>
          </label>
        </div>
      </div>
      
      <!-- Фильтр Доставка из зарубежа -->
      <div class="d-flex flex-column mb-5 mb-md-7 fv-row">
        <div class="d-flex flex-stack my-2">
          <div class="me-5">
            <label class="d-flex align-items-center form-label">
              {{ $t('modal.label.global') }}
              <KTIcon 
                icon-name="pointers" 
                icon-class="fs-2 text-gray-600 ms-2"
              />
            </label>              
            <div class="text-muted fs-7">
              {{ $t('modal.subLabel.global') }}
            </div>
          </div>
        
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.isGlobal == 1"
              @change="toggleGlobal"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.byTitle') }}
            </span>
          </label>
        </div>
      </div>

    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 3-->
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";

import Tagify from '@/components/Tagify.vue';
import noUiSlider from 'nouislider/dist/nouislider.js';

export default defineComponent({
  name: "item-modal-step3",
  components: {
    ErrorMessage,
    Field,
    Tagify,
  },
  methods: {
    getGroupWords(groupId, field) {
      const group = this.itemStore.groups.find(item => item.id === groupId);
      return group?.[field] || '';
    },
    getGroupTitle(groupId) {
      const group = this.itemStore.groups.find(item => item.id === groupId);
      return group?.title || '';
    },
  },
  props: {
    localItemData: {
      type: Object,
      required: true
    },
    itemStore: {
      type: Object as () => ItemData,
      required: true
    },
    onReset: {
      type: Function,
      required: true
    },
  },
  setup(props) {
    const { t } = useI18n();
    const aiFilterSlider = ref<HTMLElement | null>(null); // Ссылка на ползунок AI фильтра
    
    // Функция для переключения AI фильтра (включение/выключение)
    const toggleAI = () => {
      props.localItemData.ai_status = props.localItemData.ai_status === '1' ? '0' : '1';
    };

    // Функция для переключения фильтра Уцененных товаров (включение/выключение)
    const toggleDiscounted = () => {
      props.localItemData.isDiscounted = props.localItemData.isDiscounted === '1' ? '0' : '1';
    };

    // Функция для переключения фильтра Оригинальных товаров (включение/выключение)
    const toggleOriginal = () => {
      props.localItemData.isOriginal = props.localItemData.isOriginal === '1' ? '0' : '1';
    };

    // Функция для переключения фильтра Доставка из зарубежа (включение/выключение)
    const toggleGlobal = () => {
      props.localItemData.isGlobal = props.localItemData.isGlobal === '1' ? '0' : '1';
    };
    
    // Инициализация ползунка для AI фильтра
    const initAIFilter = () => {
      aiFilterSlider.value = document.getElementById("ai_filter_slider");
      if (aiFilterSlider.value) {
    
        // Удаляем предыдущие события и слайдер, если они есть
        aiFilterSlider.value.noUiSlider?.off();
        aiFilterSlider.value.noUiSlider?.destroy();
        
        // Создаем новый слайдер
        noUiSlider.create(aiFilterSlider.value, {
          start: [props.localItemData.ai_score * 100], // Умножаем на 100 для отображения в процентах
          tooltips: {
            to: (value) => `${Math.round(value)}%`, // Отображение в процентах
            from: (value) => value / 100 // Преобразование обратно в число
          },
          connect: [false, true],
          range: { min: 20, max: 100 }, // Диапазон значений в процентах
        });
        
        // Обновляем локальные данные при изменении ползунка
        aiFilterSlider.value.noUiSlider?.on('update', function (value) {
          props.localItemData.ai_score = value[0] / 100;
        });
      }
    }

    onMounted(async () => {
      initAIFilter(); // Инициализация слайдера
    });

    // Удаляем слайдер и события при размонтировании компонента
    onBeforeUnmount(() => {
      aiFilterSlider.value?.noUiSlider?.off(); 
      aiFilterSlider.value?.noUiSlider?.destroy();
    });
    
    // Обновляем значения слайдера при сбросе
    const handleReset = () => {
      aiFilterSlider.value?.noUiSlider?.updateOptions({
        start: [props.localItemData.ai_score * 100] // Умножаем на 100 для отображения в процентах
      });
    };
    
    // Отслеживаем сброс данных и вызываем handleReset
    watch(() => props.onReset, (newVal) => {
      if (newVal) {
        handleReset();
      }
    });

    // Отслеживаем изменения в статусе AI фильтра и обновляем слайдер
    watch(() => props.localItemData.ai_status, (newStatus) => {
      if (aiFilterSlider.value) {
        handleReset();
        newStatus === '1' ? aiFilterSlider.value.noUiSlider?.enable() : aiFilterSlider.value.noUiSlider?.disable();
      }
    }, { deep: true });

    return {
      toggleAI,
      toggleDiscounted,
      toggleOriginal,
      toggleGlobal,
    };
  },
});
</script>
