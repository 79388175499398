const ru = {
  loadingText: "Загрузка...",
  returnHome: "Вернуться на главную",
  goHome: "Перейти на главную",
  offline: "Нет подключения к интернету",
  
  theme: {
    light: "Светлая",
    dark: "Темная",
    system: "Системная"
  },

  info: "Информация",
  // terms: "Условия",
  about: "О проекте",
  docs: "Документация",
  contact: "Контакты",
  support: "Техническая поддержка",
  userAgreement: "Пользовательское соглашение",
  privacyPolicy: "Политика конфиденциальности",

  city: "Город",
  phone: "Телефон",
  email: "Эл. почта",
  avatar: "Фото",
  search: "Поиск",
  soon: "Скоро",

  actions: {
    edit: "Редактировать",
    archive: "Архивировать",
    dearchive: "Восстановить",
    purchased: "Куплено",
    correctPrice: "Исправить цену",
    delete: "Удалить",
    clear: "Очистить",
  },
  buttons: {
    discard: "Сбросить",
    cancel: "Отмена",
    submit: "Отправить",
    save: "Сохранить",
    wait: "Подождите...",
    continue: "Продолжить",
    back: "Назад",
    connect: "Подключить",
    allow: "Разрешить",
    update: "Обновить",
    change: "Изменить",
    agree: "Согласен",
    close: "Закрыть",
    remove: "Удалить",
    import: "Импортировать",
    export: "Экспортировать",
    rectify: "Исправить",
  },
  messager: {
    telegram: "Telegram",
    google: "Google",
    apple: "Apple",
  },
  cookieMessage: {
    introText: "Мы используем",
    cookieText: "cookie",
  },
  charts: {
    platform: {
      items: "Товаров",
      links: "Ссылок",
    },
  },
  messages: {
    text: {
      success: {
        signIn: "Вы успешно вошли в систему!",
        signUp: "Вы успешно зарегистрированы!",
        passwordReset: "Письмо отправлено на вашу эл. почту!",
        passwordChange: "Вы успешно изменили пароль!",
        emailChange: "Вы успешно изменили эл. почту!",
        userUpdate: "Данные профиля обновлены!",
        
        addGroup: "Группа успешно добавлена!",
        editGroup: "Группа успешно изменена!",
        deleteGroup: "Группа успешно удалена!",
        
        addSearch: "Поиск успешно добавлен!",
        editSearch: "Поиск успешно изменен!",
        archive: "Поиск успешно архивирован!",
        dearchive: "Поиск успешно восстановлен!",
        deleteSearch: "Поиск успешно удалён!",
        importSearch: "Данные из CSV файла импортированы!",
        exportSearch: "Данные экспортированы в CSV файл!",

        clearImage: "Картинка успешно удалена!",
        clearItems: "Список товаров успешно очищен!",
        clearAnalytics: "Аналитика успешно очищена!",
        clearNotify: "Уведомления успешно архивированы!",
        
        deleteAnalyticsItems: "Товары успешно удалёны!",

        switchOnNotices: "Уведомления через {messager} успешно подключены.",
        switchOffNotices: "Уведомления через {messager} успешно отключены.",
        switchOffAccount: "Аккаунт {service} успешно отключен.",
        switchOnAccount: "Аккаунт {service} успешно подключен.",
        switchNoticesTime: "Время оповещения успешно изменено.",
        switchOnNoticesPause: "Режим «Не беспокоить» включен.",
        switchOffNoticesPause: "Режим «Не беспокоить» выключен.",

        markPurchased: "Покупка успешно записана.",
      },
      error: {
        oauth: "Ошибка при попытке аутентификации. Пожалуйста, попробуйте еще раз!",

        addGroup: "Ошибка при добавлении группы. Пожалуйста, попробуйте еще раз!",
        editGroup: "Ошибка при изменении группы. Пожалуйста, попробуйте еще раз!",
        deleteGroup: "Ошибка при удалении группы. Пожалуйста, попробуйте еще раз!",

        addSearch: "Ошибка при добавлении поиска. Пожалуйста, попробуйте еще раз!",
        editSearch: "Ошибка при изменении поиска. Пожалуйста, попробуйте еще раз!",
        archive: "Ошибка при архивировании поиска. Пожалуйста, попробуйте еще раз!",
        dearchive: "Ошибка при восстановлении поиска из архива. Пожалуйста, попробуйте ещё раз!",
        deleteSearch: "Ошибка при удалении поиска. Пожалуйста, попробуйте еще раз!",
        importSearch: "Ошибка при добавлении данных из CSV файла. Пожалуйста, попробуйте еще раз!",

        clearImage: "Ошибка при удалении картинки. Пожалуйста, попробуйте еще раз!",
        clearItems: "Ошибка при очистке товаров. Пожалуйста, попробуйте еще раз!",
        clearAnalytics: "Ошибка при очистке аналитики. Пожалуйста, попробуйте еще раз!",

        deleteItems: "Ошибка при удалении товаров. Пожалуйста, попробуйте еще раз!",
        
        switchOnNotices: "Ошибка при подключении уведомлений через {messager}. Попробуйте еще раз!",
        switchOffNotices: "Ошибка при отключении уведомлений через {messager}. Попробуйте еще раз!",
        switchOnAccount: "Ошибка при подключении аккаунта {service}. Пожалуйста, попробуйте еще раз!",
        switchOffAccount: "Ошибка при отключении аккаунта {service}. Пожалуйста, попробуйте еще раз!",
        switchNoticesTime: "Ошибка при изменении времени оповещения. Пожалуйста, попробуйте еще раз!",
        switchOnNoticesPause: "Ошибка при включении режима «Не беспокоить». Попробуйте еще раз!",
        switchOffNoticesPause: "Ошибка при выключении режима «Не беспокоить». Попробуйте еще раз!",

        geoCoords: "Ошибка при получении координат!",
        geoCity: "Ошибка при получении города!",
        geoCityNotFound: "Город не найден",
        
        markPurchased: "Ошибка при добавлении покупки. Пожалуйста, попробуйте еще раз!",
        markPurchasedEmpty: "Необходимо указать стоимость товара!",
        markPurchasedOnlyNumbers: "Допустимы только цифры!",
      },
      warning: {
        passwordChangeFail: "Используйте ссылку из письма для восстановления пароля!",

        addGroup: "Группа с таким названием уже существует!",
        invalidGroupName: "Название группы не должно быть числом!",
        maxLengthExceeded: "Название группы не должно быть длиннее, чем {maxLength} символов!",
        
        deleteGroup: "Вы действительно хотите удалить группу: {title}?",
        deleteSearch: "Вы действительно хотите удалить поиск: {title}?",
        archive: "Вы действительно хотите архивировать поиск: {title}?",
        dearchive: "Вы действительно хотите восстановить из архива поиск: {title}?",
        clearItems: "Вы действительно хотите очистить список товаров для поиска: {title}?",
        clearAnalytics: "Вы действительно хотите очистить данные аналитики для поиска: {title}?",
      
        deleteAnalyticsItems: "Удалить выбраные товары?",

        switchOffNotices: "Вы действительно хотите отключить уведомления через {messager}?",
        switchOffAccount: "Вы действительно хотите отключить аккаунт {service}?",
        geoNotAvailable: "Геолокация не доступна!",
        
        markPurchased: "Укажите по какой цене вы купили товар: {title}.",
      }
    },
    button: {
      okGotIt: "Хорошо, понял!",
      noCancel: "Нет, отменить",
      tryAgain: "Попробовать ещё раз!",
      archive: "Архивировать!",
      dearchive: "Восстановить!",
      delete: "Удалить!",
      clear: "Очистить!",
      switchOff: "Отключить"
    },
    any: {
      success: "Успех",
      warning: "Предупреждение",
      error: "Ошибка",
    },
    label: {
      markPurchased: "Если купили товар, укажите по какой цене:",
    }
  },
  menu: {
    home: "Главная",
    dashboard: "Панель",
    layoutBuilder: "Конструктор",
    groups: "Группы",
    search: "Поиск",
    itemsId: "Товары",
    profile: "Профиль",
    setting: "Настройки",
    userAccount: {
      profile: "Профиль",
      language: "Язык",
      logs: "Сеансы",
      notifications: "Уведомления",
      settings: "Настройки",
      signOut: "Выйти"
    }
  },
  navbar: {
    notify: {
      notifications: "Уведомления",
      tabs: {
        all: "Все",
        items: "Товары",
        problems: "Проблемы",
        information: "Информация",
      },
      title: {
        searchRemoved: "Поиcк удален",
      },
      alert: {
        linkDeactivated: "Ссылка {platform} деактивирована",
        noNotifications: "Нет уведомлений",
        unknown: "Неизвестно уведомление",
      },
    },
  },
  toolbar: {
    upgradePlan: "Подписка"
  },
  widgets: {
    dashboard: {
      savings: {
        real: {
          title: "Реальная экономия",
          subtitle: "Подтвержденные данные",
          formatter: {
            single: "товар куплен",
            few: "товара куплено",
            many: "товаров куплено",
          },
        },
        potential: {
          title: "Возможная выгода",
          subtitle: "Потенциальная экономия",
          information: "Информация на",
          noInformation: "Нет аналитики",
        },
        period: "Месяц",
      }
    }
  },
  pages: {
    authentication: {
      title: "Быстрый и эффективный персональный помощник",
      subtitle: "Palert — это ваш персональный помощник в мире онлайн-шопинга для тех, кто хочет сэкономить на покупке дорогих товаров. С нашим приложением вы всегда будете в курсе лучших предложений на любимые товары. С Palert вы не пропустите ни одной выгодной сделки!",
      signIn: {
        pageTitle: "Вход",
        subtitle: "через сервисы",
        noAccount: "Нет аккаунта?",
        signUp: "Зарегистрироваться",
        forgotPassword: "Восстановить",
        button: "Войти",
      },
      signUp: {
        pageTitle: "Регистрация",
        subtitle: "через сервисы",
        alreadyAccount: "Уже есть аккаунт?",
        signIn: "Войти",
        firstName: "Имя",
        lastName: "Фамилия",
        textPassword: "Используйте 8 и более символов: буквы, цифры и символы.",
        textAgree: "Я принимаю условия ",
        textTermsUserAgreement: "соглашения о пользовании информационной системой и персональным помощником Palert",
        textAgreePrefix: " и ознакомлен с ",
        textTermsPrivacyPolicy: "политикой в отношении обработки персональных данных",
        button: "Зарегистрироваться",
      },
      passwordReset: {
        pageTitle: "Восстановление пароля",
        subtitle: "Введите свою эл. почту, чтобы сбросить пароль",
        button: "Сбросить",
        wait: "Сброс...",
      },
      passwordChange: {
        pageTitle: "Установка нового пароля",
        subtitle: "Вы уже сбросили пароль?",
      },
      passwordConfirmation: {
        pageTitle: "Пароль изменен",
        title: "Пароль изменен",
        subtitle: "Вы успешно изменили пароль!",
      },
      welcome: {
        pageTitle: "Добро пожаловать",
        title: "Добро пожаловать в Palert",
        subtitle: "Мы рады приветствовать вас в нашем проекте. Ваш аккаунт был успешно создан. Письмо со ссылкой для активации аккаунта и дальнейшими инструкциями отправлено на указанную при регистрации почту.",
      },
      verifyEmail: {
        pageTitle: "Подтверждение эл. почты",
        title: "Эл. почта подтверждена",
        titleError: "Ошибка подтверждения эл. почты",
        subtitle: "Вы успешно подтвердили эл. почту!",
        processGoHome: "Через {second} сек. перенаправим вас на главную.",
      },
      acceptAgreement: {
        pageTitle: "Примите пользовательское соглашение",
        title: "Примете пользовательское соглашение",
        subtitle: "Для использования Palert необходимо принять соглашение.",
      },
      accountDeactivated: {
        pageTitle: "Учетная запись деактивирована",
        title: "Ваша учетная запись деактивирована",
        subtitle: "Спасибо, что были с нами на протяжении долгого времени!",
      },
      error404: {
        pageTitle: "Ошибка 404",
        title: "Упс!",
        subtitle: "Мы не можем найти эту страницу."
      },
      error500: {
        pageTitle: "Ошибка 500",
        title: "Системная ошибка",
        subtitle: "Что-то пошло не так! Пожалуйста, повторите попытку позже."
      },
      email: "Эл. почта",
      password: "Пароль",
      confirmPassword: "Повторите пароль",
      or: "ИЛИ",
      wait: "Пожалуйста, подождите...",
      errorOAuthLink: "Ошибка при получении ссылки аутентификации:"
    },
    dashboard: {
      breadcrumbs: "Рабочий стол",
      pageTitle: "Рабочий стол",
      platforms: {
        titleCard: "Маркетплейсы",
        subTitleCard: "Актуальные данные",
        formatter: {
          single: "ссылка",
          few: "ссылки",
          many: "ссылок"
        },
      },
    },
    account: {
      breadcrumbs: {
        items: "Профиль",
      },
      pageTitleOverview: "Профиль",
      pageTitleSettings: "Настройка профиля",
      pageTitleNotifications: "Уведомления",
      pageTitleLogs: "Журнал активности",
      nav: {
        overview: "Обзор",
        settings: "Настройки",
        notifications: "Уведомления",
        logs: "Журнал активности",
      },
      layoutOverview: {
        profileDetails: "Подробности профиля",
        fullName: "Полное имя",
        mobilePhone: "Мобильный",
        mobileTitle: "Номер телефона должен быть активным",
        avatarTitle: "Загрузите подходящий вариант, который будет отображаться на Palert",
        communication: "Общение",
      },
      layoutSettings: {
        linkedAccounts: "Связанные аккаунты",
        numberPhone: "Номер телефона",
        signInMethod: "Способ входа",
        changeEmail: "Изменить эл. почту",
        newEmail: "Новый адрес эл. почты",
        
        place: "Место",
        coordinates: "Координаты",
        latitude: "Широта",
        longitude: "Долгота",
        timezone: "Часовой пояс",
        filled: "Заполняется автоматически",
        filledTitle: "Поле {label} заполняется автоматически при добавлении города.",

        updatePassword: "Обновить пароль",
        currentPassword: "Текущий пароль",
        newPassword: "Новый пароль",
        confirmNewPassword: "Повторите новый пароль",        
        accountNotConnected: "Аккаунт не подключен",
        
        deactivateAccount: "Деактивировать аккаунт",
        deactivateWrapperTitle: "Вы отключаете свой аккаунт",
        deactivateWrapperText: "Для дополнительной безопасности при отключении аккаунта необходимо подтвердить свой адрес эл. почты.",
        deactivateConfirm: "Подтвердите деактивацию аккаунта",
      },
      layoutNotifications: {
        notifyChannels: "Каналы уведомлений",
        notifyTimes: "Время оповещения",
        notifications: "Уведомления",
        notifyNotLabel: "отсутствует",

        infoNotifiTGTitle: "Подключите уведомления в Telegram",
        infoNotifiTGText: "Пожалуйста, перейдите в нашего Telegram-бота {telegramBot} и нажмите \"<strong>Start</strong>\". Это позволит отправлять вам уведомления в Telegram.",

        infoConnectedTGTitle: "Подключите Telegram аккаунт",
        infoConnectedTGText: "Пожалуйста, подключите Telegram аккаунт в ",

        infoConnectedPhoneTitle: "Укажите номер телефона",
        infoConnectedPhoneText: "Пожалуйста, укажите номер мобильного телефона в ",
        
        infoConnectedLink: "Настройках профиля",
        
        notifyNotDisturb: "Не беспокоить",
        notifyNotDisturbText: "Активируйте режим «Не беспокоить», чтобы отключить все уведомления и сосредоточиться без помех в определенные часы или во время выполнения задач.",
        notifyNotDisturbSubText: "При активации этого режима вы не получите уведомлений до его отключения.",
        notifyNotDisturbButton: "Пауза уведомлений",
        notifyDisturbButton: "Вкл. уведомления",

        times: {
          morning: "Утро",
          dayEvening: "День-Вечер",
          night: "Ночь",
        },
        subTimes: {
          morning: "с 7:00 до 12:00",
          dayEvening: "с 12:00 до 22:00",
          night: "с 22:00 до 7:00",
        },
        label: {
          items: "Товары",
          bills: "Счета",
          newDevice: "Новое устройство",
          news: "Новости",
        },
        subLabel: {
          items: "Оповещение о достижении желаемой цены",
          bills: "Оповещение о новых и неоплаченных счетах",
          newDevice: "Оповещение о входах с нового устройства",
          news: "По мере выхода интересных новостей",
        },
      },
      layoutLogs: {
        loginSessions: "Журнал активности",
        titleTable: {
          device: "Устройство",
          location: "Место",
          browser: "Браузер",
          ip: "IP-адрес",
          type: "Действие",
          date: "Дата",
        },
        select: {
          day: "День",
          week: "Неделя",
          month: "Месяц",
          year: "Год",
        },
        type_text: {
          USER_AUTHORIZE: "Вход",
          USER_PASSWORD_CHANGED: "Смена пароля",
          USER_EDIT: "Изм. профиля",
        }
      },
    },
    groups: {
      breadcrumbs: {
        groups: "Группы",
      },
      pageTitle: "Управление группами",
      addGroup: "Добавить",
      titleCard: "Группы",
      titleTable: {
        groups: "Названия",
        status: "Статус",
        action: "Действия",
      },
      formatter: {
        total: "Всего",
        single: "группа",
        few: "группы",
        many: "групп"
      },
      modal: {
        title: {
          new: "Добавить новую группу",
          edit: "Редактировать группу",
        },
        label: {
          description: "Описание"
        },
        placeholder: {
          title: "Название группы",
          description: "Введите описание"
        },
        subLabel: {
          title: "Используйте название, которое вам удобно.",
          description: "Пожалуйста, напишите описание для лучшего понимания того, что будет в группе.",
          requiredWords: "Слова, которые будут добавляться к каждому поиску из этой группы и использоваться для фильтрации товаров, которые должны содержать эти слова в названии.",
          excludedWords: "Слова, которые будут добавляться к каждому поиску из этой группы и исключать товары из результатов, если они присутствуют в названии.",
          pict: "Сгенерирует ChatGPT",
        },
        other: {
          checkImage: "Загрузить",
        }
      }
    },
    items: {
      breadcrumbs: {
        items: "Поиск",
      },
      pageTitle: "Список отслеживаемых товаров",
      addItem: "Добавить",
      filterItem: "Фильтр",
      filter: {
        // title: "Опции фильтрации",
        groups: "Группы",
        allGroups: "Все группы",
        platforms: "Платформы",
        wishprice: "Ожидаемая цена",
        executed: "Выполнено",
        purchased: "Куплено",
        status: "Статус",
        active: "Активный",
        archive: "Архив",
        any: "Любой",
        reset: "Сбросить"
      },
      titleCard: "Поиски",
      titleCardLast: "Последние поиски",
      titleTable: {
        search: "Поиск",
        price: "Мин. Цена",
        platform: "Платформы",
        items: "Товаров",
        updated: "Обновлено",
        chart: "График",
        status: "Статус",
        action: "Действия",
        filters: "Фильтры",
      },
      formatter: {
        total: "Всего",
        single: "товар",
        few: "товара",
        many: "товаров"
      },
      active: "Активно",
      deactive: "Неактивно",
      archive: "Архив",
      isLinks: "Нет ссылок",
      discounted: "Уцененный товар",
      groupUnspecified: "Группа не указана",
      priceUnspecified: "Цена не задана",
      modal: {
        title: {
          new: "Добавить новый поиск",
          edit: "Редактировать поиск"
        },
        stepper: {
          main: "Параметры",
          links: "Ссылки",
          filters: "Фильтры",
        },
        label: {
          requiredWords: "Обязательные слова",
          excludedWords: "Исключаемые слова",
          link: "Ссылки или артикулы",
          wishprice: "Ожидаемая цена",
          group: "Группа",
          byTitle: "Искать по названию",
        },
        placeholder: {
          title: "Название поиска",
          requiredWords: "Введите слово",
          excludedWords: "Введите слово",
          wishprice: "10 000 ₽",
          group: {
            add: "Введите название группы",
            select: "Выберите группу",
            emptyList: "Список групп пуст",
            notFound: "Группа не найдена"
          },
        },
        subLabel: {
          title: "Укажите название поиска, на основе которого AI (Искусственный интеллект) будет фильтровать товары с маркетплейсов.",
          requiredWords: "Укажите слова, которые обязательно должны быть в названии товара.",
          excludedWords: "Укажите слова, которых не должно быть в названии товара.",
          groupWords: "Слова, подсвеченные зелёным, автоматически добавлены из группы {group}.",
          link: "Ссылки или артикулы (SKU) на {platform}.",
          wishprice: "Укажите цену, при снижении до которой вы хотите получать уведомления.",
          group: {
            add: "Создайте группу для добавления поиска.",
            select: "Выберите группу для добавления поиска.",
          },
          pict: "Подгрузить из товаров",
          byTitle: "Сформировать ссылки исходня из названия",
        },
        other: {
          optionGroup: "Выберите группу...",
          checkImage: "Загрузить",
          byTitle: "Вкл.",
        },
        error: {
          link: "Ссылка исключена из поиска после {count} неудачных попыток получить данные. Проверьте корректность ссылки и нажмите Сохранить, чтобы проверить еще один раз.",
          tooltip: "Ссылка исключена из поиска. Проверьте корректность ссылки.",
        }
      }
    },
    searchId: {
      breadcrumbs: {
        items: "Товары",
      },
      pageTitleList: "Список товаров",
      pageTitleAnalytics: "Аналитика товаров",
      pageTitleLinks: "Ссылки для поиска товаров",
      bestOffer: "Лучшее предложение",
      added: "Добавлено",
      modified: "Изменено",
      requiredWords: "Обязательные слова",
      excludedWords: "Исключаемые слова",
      filters: "Вкл. Фильтры",
      itemRemoved: "Товар удален",
      actionsMenu: {
        search: "Поиск",
        image: "Картинка",
        products: "Товары",
        analytics: "Аналитика"
      },
      nav: {
        list: "Список",
        analytics: "Аналитика",
        links: "Ссылки"
      },
      layoutList: {
        total: "всего",
        active: "Все",
        allPlatforms: "Все платформы",
        titleCard: "Товары",
        titleTable: {
          items: "Названия",
          price: "Цена",
          quantity: "Наличие",
          platform: "Платформы",
          brand: "Бренд",
          rating: "Рейтинг",
          ai_score: "Оценка AI",
          sku: "SKU",
          delivery: "Доставка",
          date: "Дата обнов.",
        }
      },
      template: {
        pcs: "шт.",
        many: "много",
      },
      layoutAnalytics: {
        bestGraph: {
          title: "История цены",
          subtitle: "Диапозон: {range}",
          period: {
            year: "Год",
            month: "Месяц",
            week: "Неделя"
          }
        },
        modal: {
          uniqueWords: {
            title: "Рекомендации",
            subtitle: "Чтобы аналогичные товары больше не появлялись в вашем поиске, добавьте Обязательные и/или Исключаемые слова.",
            requiredWords: "Рекомендуем добавить в Обязательные слова",
            excludedWords: "Рекомендуем добавить в Исключаемые слова",
          }
        },
      },
      layoutLinks: {
        total: "всего",
        titleCard: "Ссылки",
        titleTable: {
          link: "Ссылка",
          count: "Товаров",
          status: "Статус",
          success: "Успешно",
          unavailable: "Нет товара",
          fail: "Ошибки",
          next_run: "След. запуск",
          links_date_update: "Дата обнов.",
        }
      },
      swal: {
        purchaseMark: {
          inputPlaceholder: "Стоимость товара, ₽",
        }
      }
    }
  },
  modal: {
    label: {
      title: "Название",
      thumbnail: "Картинка",
      status: "Статус",
      active: "Активный",
      ai_score: "Оценка AI",
      original: "Оригинальные товары",
      discounted: "Уцененные товары",
      global: "Товары из-за рубежа",
    },
    subLabel: {
      status: "Cразу активировать",
      ai_filter: "Укажите минимальный уровень оценки, ниже которого товары, не соответствующие названию, будут исключены из поиска. AI (Искусственный интеллект) помогает отфильтровать такие товары, основываясь на выбранной вами оценке.",
      original: "Искать только оригинальные товары",
      discounted: "Учитывать уценённые товары при поиске",
      global: "Учитывать товары из-за рубежа",
    },
    upgradePlan: {
      title: "Повышение уровня подписки",
      subtitle: "Подберите тариф, который соответствует вашим требованиям, и наслаждайтесь удобством отслеживания цен в режиме реального времени.",
      currentPlan: "Текущий",
      question: "Подписка {plan}",
      nav: {
        monthly: "Ежемесячно",
        annual: "Ежегодно"
      },
      month: "мес",
      year: "год",
      btn: {
        findPrice: "Узнать",
        cancel: "Отменить",
        upgrade: "Обновить подписку"
      }
    },
    csv: {
      title: {
        import: "Импортировать CSV файл",
        export: "Экспортировать в CSV файл",
      },
      file: "CSV",
      import: "Импорт",
      export: "Экспорт",
      dropzone: {
        label: "Перенесите файл сюда или нажмите, чтобы загрузить",
        sublabel: "Загрузите CSV файл"
      } 
    }
  },
  validation: {
    required: "Поле обязательно для заполнения",
    requiredField: "Поле {field} обязательно для заполнения",
    requiredConsent: "Вы должны принять условия",
    url: "Некорректная ссылка",
    urlOrSKU: "Некорректная ссылка или артикул",
    
    email: "Пожалуйста, введите действительную эл. почту",
    min: "Минимальная длина {min}",
    max: "Максимальная длина {max}",
    
    passwordsMustMatch: "Пароли должны совпадать",
    passwordMustBeDifferent: "Новый пароль не должен совпадать с текущим",
    emailMustBeDifferent: "Новая эл. почта не должна совпадать с действующей",
  },
  components: {
    ImageUploader: {
      subLabel_1: "Изображение будет обрезано до квадрата.",
      subLabel_2: "Принимаются только файлы изображений форматов *.png, *.jpg, *.jpeg и *.webp.",
      change: "Изменить изображение",
      remove: "Удалить изображение",
    }
  },
  error: {
    noResponseServer: "Нет ответа от сервера. Пожалуйста, проверьте ваше интернет-соединение!",
    generalError: "Произошла ошибка. Пожалуйста, попробуйте позже!",
    unknown: "Неизвестная ошибка. Пожалуйста, повторите попытку позже!",
    server: "Ошибка на сервере. Пожалуйста, повторите попытку позже!",    

    AUTH_FAILED: "Ошибка при попытке авторизации. Пожалуйста, повторите попытку позже!",
    REGISTER_FAILED: "Ошибка при попытке регистрации. Пожалуйста, повторите попытку позже!",
    PASSWORD_FAILED: "Ошибка при попытке изменить пароль. Пожалуйста, повторите попытку позже!",
    EMAIL_FAILED: "Ошибка при попытке изменить адрес эл. почты. Пожалуйста, повторите попытку позже!",
    
    USER_NOT_ACTIVE: "Аккаунт пользователя не автивирован. Пожалуйста, используйте ссылку из письма! Или используйте Восстановить пароль, чтобы письмо отправилось повторно.",
    USER_BLOCKED: "Аккаунт пользователя заблокирован. Пожалуйста, обратитесь в поддержку.",
    USER_NOT_FOUND: "Аккаунт пользователя не найден. Пожалуйста, используйте ссылку из письма!",
    EMAIL_ALREADY_VERIFIED: "Эл. почта пользователя уже подтверждена.",
    VERIFY_CODE_MISSING: "Не указан код подтверждения эл. почты. Пожалуйста, используйте ссылку из письма!",
    VERIFY_CODE_INVALID: "Указан неверный код подтверждения эл. почты. Пожалуйста, используйте ссылку из письма!",
    VERIFY_EMAIL_FAILED: "Во время подтверждения эл. почты произошла ошибка. Пожалуйста, обратитесь в поддержку.",

    PASSWORD_MUST_DIFFERENT: "Новый пароль не должен совпадать с текущим.",
    EMAIL_MUST_DIFFERENT: "Новый адрес эл. почты не должен совпадать с действующим.",
    EMAIL_ALREADY_EXISTS: "Новый адрес эл. почты уже используется.",

    AGREEMENT_NOT_ACCEPTED: "Вы должны принять условия соглашения о пользовании информационной системой.",
    AGREEMENT_ACCEPT_FAILED: "Ошибка принятия соглашения. Пожалуйста, обратитесь в поддержку.",
    
    AUTH_INVALID_CREDENTIALS: "Неверная эл. почта или пароль.",
    AUTH_INVALID_EMAIL_FORMAT: "Некорректный формат эл. почты.",
    AUTH_INVALID_PASSWORD: "Неверный текущий пароль.",
    AUTH_INVALID_PASSWORD_LENGTH: "Пароль должен содержать не менее 8 символов.",
    AUTH_REQUIRED_FIELDS_MISSING: "Поля Эл. почта и Пароль обязательны для заполнения.",
    AUTH_REQUIRED_FIELDS_EMAIL: "Поле Эл. почта обязательно для заполнения.",
    AUTH_REQUIRED_FIELDS_PASSWORD: "Поле Пароль обязательно для заполнения.",
    AUTH_USER_ALREADY_EXISTS: "Пользователь с такой эл. почтой уже зарегистрирован.",
    AUTH_USER_NOT_FOUND: "Пользователь с такой эл. почтой не зарегистрирован.",
    AUTH_SEND_PASSWORD_FAILED: "Ошибка при отправке письма для восстановления пароля. Пожалуйста, повторите попытку позже!",
    USER_UPDATE_FAILED: "Ошибка при обновлении данных профиля. Пожалуйста, попробуйте еще раз!",
    USER_UPDATE_NOTIFY_CHANNELS_FAILED: "Ошибка при изменении канала уведомлений пользователя. Пожалуйста, попробуйте еще раз!",
    USER_UPDATE_NOTIFY_TIMES_FAILED: "Ошибка при изменении времени уведомлений пользователя. Пожалуйста, попробуйте еще раз!",

    GROUP_LIMIT_EXCEEDED: "Превышен лимит по количеству групп.",
    SEARCH_LIMIT_EXCEEDED: "Превышен лимит по количеству поисков.",

    DEMO_ACCESS_DENIED_UPDATE_USER: "В демонстрационной учетной записи нельзя изменять профиль.",
    DEMO_ACCESS_DENIED_ADD_GROUP: "В демонстрационной учетной записи нельзя добавлять новые группы.",
    DEMO_ACCESS_DENIED_EDIT_GROUP: "В демонстрационной учетной записи нельзя изменять существующие группы.",
    DEMO_ACCESS_DENIED_DELETE_GROUP: "В демонстрационной учетной записи нельзя удалять созданные группы.",
    DEMO_ACCESS_DENIED_ADD_SEARCH: "В демонстрационной учетной записи нельзя добавлять новые поиски.",
    DEMO_ACCESS_DENIED_EDIT_SEARCH: "В демонстрационной учетной записи нельзя изменять параметры поисков.",
    DEMO_ACCESS_DENIED_ARCHIVE_SEARCH: "В демонстрационной учетной записи нельзя архивировать поиски.",
    DEMO_ACCESS_DENIED_DELETE_SEARCH: "В демонстрационной учетной записи нельзя удалять существующие поиски.",
    DEMO_ACCESS_DENIED_CLEAR_ITEMS: "В демонстрационной учетной записи нельзя очищать список товаров.",
    DEMO_ACCESS_DENIED_CLEAR_ANALYTICS: "В демонстрационной учетной записи нельзя очищать данные аналитики.",
    DEMO_ACCESS_DENIED_ARCHIVE_NOTIFY: "В демонстрационной учетной записи нельзя архивировать уведомления.",
    DEMO_ACCESS_DENIED_MARK_PURCHASED: "В демонстрационной учетной записи нельзя отмечать покупки.",
  },
  dataTable: {
    emptyTable: "Ничего не найдено",
    zeroRecords: "Нет совпадающих записей",
    paginate: {
      first: "Первая",
      previous: "Предыдущая",
      next: "Следующая",
      last: "Последняя"
    },
    lengthMenu: "Показать _MENU_ записей",
    info: "Показано с _START_ по _END_ из _TOTAL_ записей",
    infoEmpty: "Показано с 0 по 0 из 0 записей",
    infoFiltered: "(отфильтровано из _MAX_ записей)",
    search: "Поиск:",
    processing: "Обработка...",
    loadingRecords: "Загрузка записей...",
    aria: {
      sortAscending: ": активируйте для сортировки столбца по возрастанию",
      sortDescending: ": активируйте для сортировки столбца по убыванию"
    },
    select: {
      rows: {
        _: "Выбрано записей: %d",
        0: "Щелкните по записи для выбора",
        1: "Выбрана одна запись"
      }
    },
    buttons: {
      print: "Печать",
      colvis: "Видимость столбцов",
      copy: "Копировать",
      copyTitle: "Скопировано в буфер обмена",
      copySuccess: {
        _: "Скопировано %d строк",
        1: "Скопирована 1 строка"
      }
    },
    decimal: ",",
    thousands: ".",
    datetime: {
      previous: "Предыдущий",
      next: "Следующий",
      hours: "Часы",
      minutes: "Минуты",
      seconds: "Секунды",
      amPm: ["AM", "PM"],
      unknown: "Неизвестный"
    }
  }
};

export default ru;