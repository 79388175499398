const en = {
  loadingText: "Loading...",
  returnHome: "Return to Home",
  goHome: "Go to Home",
  offline: "No internet connection",

  theme: {
    light: "Light",
    dark: "Dark",
    system: "System"
  },

  info: "Information",
  // terms: "Terms",
  about: "About",
  docs: "Documentation",
  contact: "Contact",
  support: "Support",
  userAgreement: "User Agreement",
  privacyPolicy: "Privacy Policy",

  city: "City",
  phone: "Phone",
  email: "Email",
  avatar: "Photo",
  search: "Search",
  soon: "Soon",

  actions: {
    edit: "Edit",
    archive: "Archive",
    dearchive: "Restore",
    purchased: "Purchased",
    correctPrice: "Correct price",
    delete: "Delete",
    clear: "Clear",
  },
  buttons: {
    discard: "Discard",
    cancel: "Cancel",
    submit: "Submit",
    save: "Save",
    wait: "Please wait...",
    continue: "Continue",
    back: "Back",
    connect: "Connect",
    allow: "Allow",
    update: "Update",
    change: "Modify",
    agree: "I agree",
    close: "Close",
    remove: "Delete",
    import: "Import",
    export: "Export",
    rectify: "Fix it",
  },
  messager: {
    telegram: "Telegram",
    google: "Google",
    apple: "Apple",
  },
  cookieMessage: {
    introText: "We use",
    cookieText: "cookies",
  },
  charts: {
    platform: {
      items: "Items",
      links: "Links",
    },
  },
  messages: {
    text: {
      success: {
        signIn: "You have successfully signed in!",
        signUp: "You have successfully registered!",
        passwordReset: "An email has been sent to your inbox!",
        passwordChange: "You have successfully changed your password!",
        emailChange: "You have successfully changed your email!",
        userUpdate: "Profile data has been updated!",

        addGroup: "Group successfully added!",
        editGroup: "Group successfully updated!",
        deleteGroup: "Group successfully deleted!",

        addSearch: "Search successfully added!",
        editSearch: "Search successfully updated!",
        archive: "Search successfully archived!",
        dearchive: "Search successfully restored!",
        deleteSearch: "Search successfully deleted!",
        importSearch: "Data from CSV file imported!",
        exportSearch: "Data exported to CSV file!",

        clearImage: "Image successfully deleted!",
        clearItems: "Item list successfully cleared!",
        clearAnalytics: "Analytics successfully cleared!",
        clearNotify: "Notifications successfully archived!",

        deleteAnalyticsItems: "Goods successfully deleted!",

        switchOnNotices: "Notifications via {messager} successfully enabled.",
        switchOffNotices: "Notifications via {messager} successfully disabled.",
        switchOffAccount: "Account {service} successfully disconnected.",
        switchOnAccount: "Account {service} successfully connected.",
        switchNoticesTime: "Notification time has been successfully updated.",
        switchOnNoticesPause: "Do Not Disturb mode is now enabled.",
        switchOffNoticesPause: "Do Not Disturb mode is now disabled.",        

        markPurchased: "Purchase successfully recorded.",
      },
      error: {
        oauth: "Authentication error. Please try again!",

        addGroup: "Error adding group. Please try again!",
        editGroup: "Error updating group. Please try again!",
        deleteGroup: "Error deleting group. Please try again!",

        addSearch: "Error adding search. Please try again!",
        editSearch: "Error updating search. Please try again!",
        archive: "Error archiving search. Please try again!",
        dearchive: "Error restoring search from archive. Please try again!",
        deleteSearch: "Error deleting search. Please try again!",
        importSearch: "Error when adding data from CSV file. Please try again!",

        clearImage: "Error when deleting an image. Please try again!",
        clearItems: "Error clearing items. Please try again!",
        clearAnalytics: "Error clearing analytics. Please try again!",

        deleteItems: "Error when deleting products. Please try again!",

        switchOnNotices: "Error enabling notifications via {messager}. Please try again!",
        switchOffNotices: "Error disabling notifications via {messager}. Please try again!",
        switchOnAccount: "Error connecting account {service}. Please try again!",
        switchOffAccount: "Error disconnecting account {service}. Please try again!",
        switchNoticesTime: "An error occurred while updating the notification time. Please try again!",
        switchOnNoticesPause: "An error occurred while enabling Do Not Disturb mode. Please try again!",
        switchOffNoticesPause: "An error occurred while disabling Do Not Disturb mode. Please try again!",

        geoCoords: "Error retrieving coordinates!",
        geoCity: "Error retrieving city!",
        geoCityNotFound: "City not found",
        
        markPurchased: "Error when adding a purchase. Please try again!",
        markPurchasedEmpty: "It is necessary to specify the cost of goods!",
        markPurchasedOnlyNumbers: "Only numbers are allowed!",
      },
      warning: {
        passwordChangeFail: "Use the link in the email to reset your password!",

        addGroup: "A group with this name already exists!",
        invalidGroupName: "Group name must not be a number!",
        maxLengthExceeded: "Group name must not exceed {maxLength} characters!",

        deleteGroup: "Are you sure you want to delete the group: {title}?",
        deleteSearch: "Are you sure you want to delete the search: {title}?",
        archive: "Are you sure you want to archive the search: {title}?",
        dearchive: "Are you sure you want to restore the search: {title} from archive?",
        clearItems: "Are you sure you want to clear the item list for search: {title}?",
        clearAnalytics: "Are you sure you want to clear the analytics data for search: {title}?",

        deleteAnalyticsItems: "Delete selected items?",

        switchOffNotices: "Are you sure you want to disable notifications via {messager}?",
        switchOffAccount: "Are you sure you want to disconnect the account {service}?",
        geoNotAvailable: "Geolocation is not available!",
        
        markPurchased: "Indicate at what price you purchased the item: {title}.",
      }
    },
    button: {
      okGotIt: "Okay, got it!",
      noCancel: "No, cancel",
      tryAgain: "Try again!",
      archive: "Archive!",
      dearchive: "Restore!",
      delete: "Delete!",
      clear: "Clear!",
      switchOff: "Disconnect"
    },
    any: {
      success: "Success",
      warning: "Warning",
      error: "Error",
    },
    label: {
      markPurchased: "If you bought an item, please indicate at what price:",
    }
  },
  menu: {
    home: "Home",
    dashboard: "Dashboard",
    layoutBuilder: "Layout Builder",
    groups: "Groups",
    search: "Search",
    itemsId: "Items",
    profile: "Profile",
    setting: "Settings",
    userAccount: {
      profile: "Profile",
      language: "Language",
      logs: "Sessions",
      notifications: "Notifications",
      settings: "Settings",
      signOut: "Sign Out"
    }
  },
  navbar: {
    notify: {
      notifications: "Notifications",
      tabs: {
        all: "All",
        items: "Items",
        problems: "Problems",
        information: "Information",
      },
      title: {
        searchRemoved: "Search removed",
      },
      alert: {
        linkDeactivated: "{platform} link is deactivated",
        noNotifications: "No notifications",
        unknown: "Unknown notice",
      },
    },
  },
  toolbar: {
    upgradePlan: "Subscription"
  },
  widgets: {
    dashboard: {
      savings: {
        real: {
          title: "Real savings",
          subtitle: "Confirmed data",
          formatter: {
            single: "item purchased",
            few: "items purchased",
            many: "items purchased",
          },
        },
        potential: {
          title: "Possible benefit",
          subtitle: "Potential savings",
          information: "Information on",
          noInformation: "No analytics",
        },
        period: "Month",
      }
    }
  },
  pages: {
    authentication: {
      title: "Fast and efficient personal assistant",
      subtitle: "Palert is your personal assistant in the world of online shopping for those who want to save on expensive purchases. With our app, you'll always be aware of the best deals on your favorite products. With Palert, you won't miss a single great deal!",
      signIn: {
        pageTitle: "Sign In",
        subtitle: "via services",
        noAccount: "No account?",
        signUp: "Sign Up",
        forgotPassword: "Forgot",
        button: "Sign In",
      },
      signUp: {
        pageTitle: "Sign Up",
        subtitle: "via services",
        alreadyAccount: "Already have an account?",
        signIn: "Sign In",
        firstName: "First Name",
        lastName: "Last Name",
        textPassword: "Use 8 or more characters: letters, numbers, and symbols.",
        textAgree: "I accept the terms and conditions of ",
        textTermsUserAgreement: "the agreement on the use of the information system and personal assistant Palert",
        textAgreePrefix: " and I am familiarized with ",
        textTermsPrivacyPolicy: " policy regarding the processing of personal data",
        button: "Sign Up",
      },
      passwordReset: {
        pageTitle: "Password Reset",
        subtitle: "Enter your email to reset your password",
        button: "Reset",
        wait: "Resetting...",
      },
      passwordChange: {
        pageTitle: "Set New Password",
        subtitle: "Have you already reset your password?",
      },
      passwordConfirmation: {
        pageTitle: "Password Changed",
        title: "Password Changed",
        subtitle: "You have successfully changed your password!",
      },
      welcome: {
        pageTitle: "Welcome",
        title: "Welcome to Palert",
        subtitle: "We are happy to welcome you to our project. Your account has been successfully created. An email with the activation link and further instructions has been sent to the email address provided during registration.",
      },
      verifyEmail: {
        pageTitle: "Email Confirmation",
        title: "Email Confirmed",
        titleError: "Email confirmation error",
        subtitle: "You have successfully confirmed your email!",
        processGoHome: "In {second} seconds, we'll redirect you to the home page.",
      },
      acceptAgreement: {
        pageTitle: "Accept the user agreement",
        title: "Accept the user agreement",
        subtitle: "You must accept the agreement to use Palert.",
      },
      accountDeactivated: {
        pageTitle: "Account Deactivated",
        title: "Your account has been deactivated",
        subtitle: "Thank you for being with us for a long time!",
      },
      error404: {
        pageTitle: "Error 404",
        title: "Oops!",
        subtitle: "We can't find this page."
      },
      error500: {
        pageTitle: "Error 500",
        title: "System Error",
        subtitle: "Something went wrong! Please try again later."
      },
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      or: "OR",
      wait: "Please wait...",
      errorOAuthLink: "Error retrieving authentication link:"
    },
    dashboard: {
      breadcrumbs: "Dashboard",
      pageTitle: "Dashboard",
      platforms: {
        titleCard: "Marketplaces",
        subTitleCard: "Current Data",
        formatter: {
          single: "link",
          few: "links",
          many: "links"
        },
      },
    },
    account: {
      breadcrumbs: {
        items: "Profile",
      },
      pageTitleOverview: "Profile",
      pageTitleSettings: "Profile Settings",
      pageTitleNotifications: "Notifications",
      pageTitleLogs: "Activity log",
      nav: {
        overview: "Overview",
        settings: "Settings",
        notifications: "Notifications",
        logs: "Activity log",
      },
      layoutOverview: {
        profileDetails: "Profile Details",
        fullName: "Full Name",
        mobilePhone: "Mobile",
        mobileTitle: "Phone number must be active",
        avatarTitle: "Upload a suitable image to be displayed on Palert",
        communication: "Communication",
      },
      layoutSettings: {
        linkedAccounts: "Linked Accounts",
        numberPhone: "Phone Number",
        signInMethod: "Sign In Method",
        changeEmail: "Change Email",
        newEmail: "New Email Address",

        place: "Place",
        coordinates: "Coordinates",
        latitude: "Latitude",
        longitude: "Longitude",
        timezone: "Time zone",
        filled: "Automatically filled",
        filledTitle: "The field {label} is automatically filled when adding a city.",

        updatePassword: "Update Password",
        currentPassword: "Current Password",
        newPassword: "New Password",
        confirmNewPassword: "Confirm New Password",
        accountNotConnected: "Account not connected",

        deactivateAccount: "Deactivate Account",
        deactivateWrapperTitle: "You are deactivating your account",
        deactivateWrapperText: "For additional security when deactivating your account, you must confirm your email address.",
        deactivateConfirm: "Confirm account deactivation",
      },
      layoutNotifications: {
        notifyChannels: "Notification channels",
        notifyTimes: "Notification Times",
        notifications: "Notifications",
        notifyNotLabel: "unfilled",

        infoNotifiTGTitle: "Connect notifications in Telegram",
        infoNotifiTGText: "Please go to our Telegram bot {telegramBot} and press \"<strong>Start</strong>\". This will allow sending you notifications in Telegram.",
        
        infoConnectedTGTitle: "Connect Telegram account",
        infoConnectedTGText: "Please connect your Telegram account in the ",
        
        infoConnectedPhoneTitle: "Provide a phone number",
        infoConnectedPhoneText: "Please include your cell phone number in the ",
        
        infoConnectedLink: "Profile Settings",

        notifyNotDisturb: "Do Not Disturb",
        notifyNotDisturbText: "Activate the Do Not Disturb mode to disable all notifications and focus without interruptions during specific hours or while performing tasks.",
        notifyNotDisturbSubText: "When this mode is activated, you will not receive any notifications until it is disabled.",
        notifyNotDisturbButton: "Pause Notifications",
        notifyDisturbButton: "Enable Notifications",
        
        times: {
          morning: "Morning",
          dayEvening: "Day-Evening",
          night: "Night",
        },
        subTimes: {
          morning: "from 7:00 AM to 12:00 PM",
          dayEvening: "from 12:00 PM to 10:00 PM",
          night: "from 10:00 PM to 7:00 AM",
        },
        label: {
          items: "Items",
          bills: "Bills",
          newDevice: "New device",
          news: "News",
        },
        subLabel: {
          items: "Notification when the desired price is reached",
          bills: "Notification of new and unpaid invoices",
          newDevice: "Notification of inputs from a new device",
          news: "As interesting news comes out",
        },
      },
      layoutLogs: {
        loginSessions: "Activity log",
        titleTable: {
          device: "Device",
          location: "Location",
          browser: "Browser",
          ip: "IP-address",
          type: "Action",
          date: "Date",
        },
        select: {
          day: "Day",
          week: "Week",
          month: "Month",
          year: "Year",
        },
        type_text: {
          USER_AUTHORIZE: "Login",
          USER_PASSWORD_CHANGED: "Pass change",
          USER_EDIT: "Profile edit",
        }
      },
    },
    groups: {
      breadcrumbs: {
        groups: "Groups",
      },
      pageTitle: "Group Management",
      addGroup: "Add",
      titleCard: "Groups",
      titleTable: {
        groups: "Names",
        status: "Status",
        action: "Actions",
      },
      formatter: {
        total: "Total",
        single: "group",
        few: "groups",
        many: "groups"
      },
      modal: {
        title: {
          new: "Add New Group",
          edit: "Edit Group",
        },
        label: {
          description: "Description"
        },
        placeholder: {
          title: "Group Name",
          description: "Enter description"
        },
        subLabel: {
          title: "Use a name that is convenient for you.",
          description: "Please write a description for better understanding of what will be in the group.",
          pict: "Generated by ChatGPT",
        },
        other: {
          checkImage: "Upload",
        }
      }
    },
    items: {
      breadcrumbs: {
        items: "Search",
      },
      pageTitle: "List of Tracked Items",
      addItem: "Add",
      filterItem: "Filter",
      filter: {
        title: "Filter Options",
        groups: "Groups",
        allGroups: "All Groups",
        platforms: "Platforms",
        wishprice: "Desired Price",
        executed: "Executed",
        purchased: "Purchased",
        status: "Status",
        active: "Active",
        archive: "Archive",
        any: "Any",
        reset: "Reset"
      },
      titleCard: "Searches",
      titleCardLast: "Recent Searches",
      titleTable: {
        search: "Search",
        price: "Min. Price",
        platform: "Platforms",
        items: "Items",
        updated: "Updated",
        chart: "Chart",
        status: "Status",
        action: "Actions",
        filters: "Filters",
      },
      formatter: {
        total: "Total",
        single: "item",
        few: "items",
        many: "items"
      },
      active: "Active",
      deactive: "Inactive",
      archive: "Archive",
      isLinks: "No links",
      discounted: "Discounted goods",
      groupUnspecified: "Group not specified",
      priceUnspecified: "Price is not set",
      modal: {
        title: {
          new: "Add New Search",
          edit: "Edit Search"
        },
        stepper: {
          main: "Parameters",
          links: "Links",
          filters: "Filters",
        },
        label: {
          requiredWords: "Required Words",
          excludedWords: "Excluded Words",
          link: "Links or SKUs",
          wishprice: "Desired Price",
          group: "Group",
          byTitle: "Search by title",
        },
        placeholder: {
          title: "Search Name",
          requiredWords: "Type in the word",
          excludedWords: "Type in the word",
          wishprice: "10,000 ₽",
          group: {
            add: "Enter group name",
            select: "Select group",
            emptyList: "Group list is empty",
            notFound: "Group not found"
          },
        },
        subLabel: {
          title: "Specify the search name, based on which AI will filter items from marketplaces.",
          requiredWords: "Specify the words that must necessarily be in the name of the product.",
          excludedWords: "Specify the words that should not be in the name of the product.",
          link: "Links or SKUs on {platform}.",
          wishprice: "Specify the price at which you want to receive notifications.",
          group: {
            add: "Create a group to add the search.",
            select: "Select a group to add the search.",
          },
          pict: "Upload from items",
          byTitle: "Generate references from the title",
        },
        other: {
          optionGroup: "Select a group...",
          checkImage: "Upload",
          byTitle: "On",
        },
        error: {
          link: "The link is excluded from the search after {count} unsuccessful attempts to retrieve the data. Check if the link is correct and click Save to check again.",
          tooltip: "The link has been excluded from the search. Check if the link is correct.",
        }
      }
    },
    searchId: {
      breadcrumbs: {
        items: "Items",
      },
      pageTitleList: "Item List",
      pageTitleAnalytics: "Item Analytics",
      pageTitleLinks: "Links for product search",
      bestOffer: "Best Offer",
      added: "Added",
      modified: "Modified",
      requiredWords: "Required Words",
      excludedWords: "Excluded Words",
      filters: "Filters enabled",
      itemRemoved: "Product removed",
      actionsMenu: {
        search: "Search",
        image: "Image",
        products: "Items",
        analytics: "Analytics"
      },
      nav: {
        list: "List",
        analytics: "Analytics",
        links: "Links"
      },
      layoutList: {
        total: "total",
        active: "All",
        allPlatforms: "All platforms",
        titleCard: "Items",
        titleTable: {
          items: "Names",
          price: "Price",
          quantity: "Quantity",
          platform: "Platforms",
          brand: "Brand",
          rating: "Rating",
          ai_score: "AI Score",
          sku: "SKU",
          delivery: "Delivery",
          date: "Date updated",
        }
      },
      template: {
        pcs: "pcs.",
        many: "many",
      },
      layoutAnalytics: {
        bestGraph: {
          title: "Price History",
          subtitle: "Range: {range}",
          period: {
            year: "Year",
            month: "Month",
            week: "Week"
          }
        },
        modal: {
          uniqueWords: {
            title: "Recommendations",
            subtitle: "To prevent similar products from appearing in your search, add Required and/or Excluded words..",
            requiredWords: "Recommended to add to Required words",
            excludedWords: "Recommended to add to Excluded words",
          }
        }
      },
      layoutLinks: {
        total: "total",
        titleCard: "Links",
        titleTable: {
          link: "Link",
          count: "Goods",
          status: "Status",
          success: "Success",
          unavailable: "No goods",
          fail: "Errors",
          next_run: "Next run",
          links_date_update: "Update Date",
        }
      },
      swal: {
        purchaseMark: {
          inputPlaceholder: "Cost of item, ₽",
        }
      }
    }
  },
  modal: {
    label: {
      title: "Title",
      thumbnail: "Image",
      status: "Status",
      active: "Active",
      ai_score: "AI Score",
      original: "Original goods",
      discounted: "Discounted goods",
      global: "Goods from abroad",
    },
    subLabel: {
      status: "Activate immediately",
      ai_filter: "Specify the minimum AI score below which items not matching the title will be excluded from the search. AI helps filter such items based on your selected score.",
      original: "Only search for original goods",
      discounted: "Include discounted items in your search",
      global: "Accounting of goods received from abroad",
    },
    upgradePlan: {
      title: "Upgrade Subscription",
      subtitle: "Select the fare that suits your needs and enjoy the convenience of real-time price tracking.",
      currentPlan: "Current",
      question: "Subscription {plan}",
      nav: {
        monthly: "Monthly",
        annual: "Annually"
      },
      month: "mo",
      year: "yr",
      btn: {
        findPrice: "Learn",
        cancel: "Cancel",
        upgrade: "Upgrade Subscription"
      }
    },
    csv: {
      title: {
        import: "Import CSV file",
        export: "Export to CSV file",
      },
      file: "CSV",
      import: "Import",
      export: "Export",
      dropzone: {
        label: "Upload file here or click to download",
        sublabel: "Upload CSV file"
      } 
    }
  },
  validation: {
    required: "Field is required",
    requiredField: "Field {field} is required",
    requiredConsent: "You must accept the terms",
    url: "Invalid URL",
    urlOrSKU: "Invalid URL or SKU",

    email: "Please enter a valid email",
    min: "Minimum length is {min}",
    max: "Maximum length is {max}",

    passwordsMustMatch: "Passwords must match",
    passwordMustBeDifferent: "The new password must not be the same as the current password",
    emailMustBeDifferent: "The new email must not be the same as the current one",
  },
  components: {
    ImageUploader: {
      subLabel_1: "Image will be cropped to a square.",
      subLabel_2: "Only *.png, *.jpg, *.jpeg, and *.webp image files are accepted.",
      change: "Change image",
      remove: "Remove image",
    }
  },
  error: {
    noResponseServer: "No response from server. Please check your internet connection!",
    generalError: "An error occurred. Please try again later!",
    unknown: "Unknown error. Please try again later!",
    server: "Server error. Please try again later!",

    AUTH_FAILED: "Error during authorization. Please try again later!",
    REGISTER_FAILED: "Error during registration. Please try again later!",
    PASSWORD_FAILED: "Error changing password. Please try again later!",
    EMAIL_FAILED: "Error when trying to change your email address. Please try again later!",

    USER_NOT_ACTIVE: "User account is not activated. Please use the link from the email! Or use Recover Password to have the email resent.",
    USER_BLOCKED: "User account has been blocked. Please contact support.",
    USER_NOT_FOUND: "User account not found. Please use the link from the email!",
    EMAIL_ALREADY_VERIFIED: "The user's email has already been confirmed.",
    VERIFY_CODE_MISSING: "No email confirmation code specified. Please use the link from the email!",
    VERIFY_CODE_INVALID: "An invalid email confirmation code has been entered. Please use the link from the email!",
    VERIFY_EMAIL_FAILED: "An error occurred during email confirmation. Please contact support.",

    PASSWORD_MUST_DIFFERENT: "The new password must not be the same as the current password.",
    EMAIL_MUST_DIFFERENT: "The new email address must not be the same as the current one.",
    EMAIL_ALREADY_EXISTS: "The new email address is already in use.",

    AGREEMENT_NOT_ACCEPTED: "You must accept the terms of your agreement to use the information system.",
    AGREEMENT_ACCEPT_FAILED: "Agreement acceptance error. Please contact support.",

    AUTH_INVALID_CREDENTIALS: "Invalid email or password.",
    AUTH_INVALID_EMAIL_FORMAT: "Invalid email format.",
    AUTH_INVALID_PASSWORD: "Invalid current password.",
    AUTH_INVALID_PASSWORD_LENGTH: "Password must be at least 8 characters long.",
    AUTH_REQUIRED_FIELDS_MISSING: "Email and Password fields are required.",
    AUTH_REQUIRED_FIELDS_EMAIL: "Email field is required.",
    AUTH_REQUIRED_FIELDS_PASSWORD: "Password field is required.",
    AUTH_USER_ALREADY_EXISTS: "User with this email is already registered.",
    AUTH_USER_NOT_FOUND: "User with this email is not registered.",
    AUTH_SEND_PASSWORD_FAILED: "Error sending password reset email. Please try again later!",
    USER_UPDATE_FAILED: "Error updating profile data. Please try again!",
    USER_UPDATE_NOTIFY_CHANNELS_FAILED: "An error occurred while updating the user's notification channels. Please try again!",
    USER_UPDATE_NOTIFY_TIMES_FAILED: "An error occurred while updating the user's notification times. Please try again!",

    GROUP_LIMIT_EXCEEDED: "Group limit exceeded.",
    SEARCH_LIMIT_EXCEEDED: "Search limit exceeded.",

    DEMO_ACCESS_DENIED_UPDATE_USER: "Profile changes are not allowed in demo account.",
    DEMO_ACCESS_DENIED_ADD_GROUP: "Adding new groups is not allowed in demo account.",
    DEMO_ACCESS_DENIED_EDIT_GROUP: "Editing groups is not allowed in demo account.",
    DEMO_ACCESS_DENIED_DELETE_GROUP: "Deleting groups is not allowed in demo account.",
    DEMO_ACCESS_DENIED_ADD_SEARCH: "Adding new searches is not allowed in demo account.",
    DEMO_ACCESS_DENIED_EDIT_SEARCH: "Editing searches is not allowed in demo account.",
    DEMO_ACCESS_DENIED_ARCHIVE_SEARCH: "Archiving searches is not allowed in demo account.",
    DEMO_ACCESS_DENIED_DELETE_SEARCH: "Deleting searches is not allowed in demo account.",
    DEMO_ACCESS_DENIED_CLEAR_ITEMS: "Clearing items list is not allowed in demo account.",
    DEMO_ACCESS_DENIED_CLEAR_ANALYTICS: "Clearing analytics data is not allowed in demo account.",
    DEMO_ACCESS_DENIED_ARCHIVE_NOTIFY: "Archiving notifications is not allowed in the demo account.",
    DEMO_ACCESS_DENIED_MARK_PURCHASED: "In a demo account, you cannot mark purchases.",
  },
  dataTable: {
    emptyTable: "Nothing found",
    zeroRecords: "No matching records found",
    paginate: {
      first: "First",
      previous: "Previous",
      next: "Next",
      last: "Last"
    },
    lengthMenu: "Show _MENU_ records",
    info: "Showing _START_ to _END_ of _TOTAL_ records",
    infoEmpty: "Showing 0 to 0 of 0 records",
    infoFiltered: "(filtered from _MAX_ total records)",
    search: "Search:",
    processing: "Processing...",
    loadingRecords: "Loading records...",
    aria: {
      sortAscending: ": activate to sort column ascending",
      sortDescending: ": activate to sort column descending"
    },
    select: {
      rows: {
        _: "%d records selected",
        0: "Click a record to select",
        1: "1 record selected"
      }
    },
    buttons: {
      print: "Print",
      colvis: "Column visibility",
      copy: "Copy",
      copyTitle: "Copied to clipboard",
      copySuccess: {
        _: "%d rows copied",
        1: "1 row copied"
      }
    },
    decimal: ".",
    thousands: ",",
    datetime: {
      previous: "Previous",
      next: "Next",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
      amPm: ["AM", "PM"],
      unknown: "Unknown"
    }
  }
};

export default en;