import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useItemStore } from "@/stores/item";
import { getAssetUrl } from '@/core/helpers/assets';
import md5 from 'crypto-js/md5';

export const isBitrix24Loading = ref(false);
export const isChatOpen = ref(false);

let chatWidget: any = null;
let initializationPromise: Promise<void> | null = null;

// Инициализация виджета
export async function initializeBitrix(): Promise<void> {
  if (initializationPromise) {
    return initializationPromise;
  }

  initializationPromise = new Promise((resolve, reject) => {
    if (window.BX?.SiteButton) {
      isBitrix24Loading.value = false;
      resolve();
      return;
    }

    isBitrix24Loading.value = true;
    window.addEventListener('onBitrixLiveChat', handleBitrixLiveChatEvent, { once: true });

    const scriptUrl =
      'https://cdn-ru.bitrix24.ru/b33088228/crm/site_button/loader_2_7nl1wa.js?' +
      ((Date.now() / 60000) | 0);

    const script = document.createElement('script');
    script.async = true;
    script.src = scriptUrl;

    script.onload = () => {
      isBitrix24Loading.value = false;
      removeWidgetButton();
      resolve();
    };

    script.onerror = () => {
      isBitrix24Loading.value = false;
      reject(new Error('Ошибка загрузки скрипта Bitrix24.'));
    };

    document.body.appendChild(script);
  });

  return initializationPromise;
}

// Удаляет кнопку виджета Bitrix24
function removeWidgetButton() {
  const checkExist = setInterval(() => {
    const button = document.querySelector('.b24-widget-button-inner-container');
    if (button) {
      button.remove();
      clearInterval(checkExist);
    }
  }, 300);
}

// Удаляет copyright Bitrix24
function removeСopyright() {
  const checkExist = setInterval(() => {
    const copyright = document.querySelector('.bx-livechat-copyright');
    if (copyright) {
      copyright.remove();
      clearInterval(checkExist);
    }
  }, 100);
}

// Обработчик события onBitrixLiveChat
function handleBitrixLiveChatEvent(event: any) {
  chatWidget = event.detail.widget;
  subscribeToWidgetEvents();
  setUserData();
}

// Подписка на события открытия/закрытия виджета
function subscribeToWidgetEvents() {
  if (!chatWidget?.subscribe) {
/*     console.warn('Метод subscribe не поддерживается виджетом Bitrix24.'); */
    return;
  }

  chatWidget.subscribe({
    type: BX.LiveChatWidget.SubscriptionType.widgetOpen,
    callback: () => { 
      removeСopyright();
      isChatOpen.value = true;
    },
  });

  chatWidget.subscribe({
    type: BX.LiveChatWidget.SubscriptionType.widgetClose,
    callback: () => { isChatOpen.value = false; },
  });
}

// Установка данных пользователя
function setUserData() {
  const userStore = useAuthStore();
  const user = userStore.user;
  
  if (chatWidget && user && user.id) {
    if (!user.bitrix24_hash) {
      // Генерируем случайный MD5-хеш
      const hash = md5(`${user.id}_${location.hostname}_${Date.now()}`).toString();
      
      // Сохраняем его корректно в сторе Pinia
      userStore.user.bitrix24_hash = hash;
    
      // Отправляем на сервер для сохранения
      userStore.updateUserBitrix24({ 'UF_BITRIX24_HASH': hash });
    }
    
    chatWidget.setUserRegisterData({
      hash: user.bitrix24_hash,
      name: user.name ?? '',
      lastName: user.last_name ?? '',
      avatar: user.photo_url ? getAssetUrl(user.photo_url) : '',
      email: user.email ?? '',
    });
    
/*     const bootstrapColors: Record<string, string> = {
      primary: '#0d6efd',
      secondary: '#6c757d',
      success: '#198754',
      warning: '#ffc107',
      danger: '#dc3545',
      info: '#0dcaf0',
    }; */
    
    // Установка дополнительных данных (публикуется при начале новой сессии, расширенный формат)
    chatWidget.setCustomData([
      {"USER": {
        "NAME" : `${user.name ?? ""} ${user.last_name ?? ""} / ID: ${user.id}`,
        "AVATAR" : user.photo_url ? getAssetUrl(user.photo_url) : '',
      }},
      {"GRID": [
        {
          "NAME" : "Подписка",
          "VALUE" : user.subscription?.type ?? "",
          // "COLOR" : bootstrapColors[user.subscription?.color ?? 'secondary'],
          "DISPLAY" : "LINE"
        },
        {
          "NAME" : "E-mail",
          "VALUE" : user.email ?? '',
          "DISPLAY" : "LINE",
        },
        {
          "NAME" : "Телефон",
          "VALUE" : user.mobile_phone ?? '',
          "DISPLAY" : "LINE",
        },
      ]},
/*       {"GRID": [
        {
          "NAME": "Групп",
          "VALUE": `${itemStore.countGroup ?? 0} / ${user.subscription?.maxGroups ?? 1}`,
          "DISPLAY": "LINE"
        },
        {
          "NAME": "Поисков",
          "VALUE": `${itemStore.countSearch ?? 0} / ${user.subscription?.maxSearch ?? 1}`,
          "DISPLAY": "LINE"
        },
      ]}, */
      {"GRID": [
        {
          "NAME": "Город",
          "VALUE": user.city?.place ?? "",
          "DISPLAY": "BLOCK"
        },
      ]},
      {"GRID": [
        {
          "NAME": "Сайт",
          "VALUE": location.hostname,
          "DISPLAY": "LINE"
        },
        {
          "NAME": "Страница",
          "VALUE": "[url="+location.href+"]"+(document.title || location.href)+"[/url]",
          "DISPLAY": "LINE"
        },
        {
          "NAME": "Timezone",
          "VALUE": user?.city?.timezone ?? "",
          "DISPLAY": "LINE"
        }
      ]}
    ]);
  }
}

// Открыть чат
export async function openBitrixChat() {
  if (chatWidget?.open) {
    chatWidget.open();
  }
}

// Закрыть чат
export async function closeBitrixChat() {
  if (chatWidget?.close) {
    chatWidget.close();
  }
}

// Переключатель (toggle)
export async function toggleBitrix24Chat() {
  await initializeBitrix();

  if (!chatWidget) {
    console.warn('Виджет Bitrix24 еще не готов.');
    return;
  }

  if (isChatOpen.value) {
    closeBitrixChat();
  } else {
    openBitrixChat();
  }
}