import { stopWords } from "@/core/data/stopWords";

/**
 * Фильтрует заголовки по наличию обязательных и отсутствию исключённых слов.
 *
 * @param dataSearchs - массив объектов поиска, содержащий обязательные и исключённые слова.
 * @param title - заголовок, который нужно проверить.
 * @returns `true`, если заголовок содержит все обязательные слова и не содержит исключённых слов, иначе `false`.
 */
export function filterByWords(dataSearchs: any[], title: string, groupWords ? : any): boolean {
  // Оптимизация получения слов
  const requiredWords = getWordsArray([
    dataSearchs.required_words,
    groupWords?.required_words
  ].filter(Boolean).join(' '));

  const excludedWords = getWordsArray([
    dataSearchs.excluded_words,
    groupWords?.excluded_words
  ].filter(Boolean).join(' '));

  // Приведение заголовка к нижнему регистру
  const lowerTitle = title.toLowerCase();

  // Проверка обязательных слов
  const hasRequiredWords = requiredWords.length === 0 || requiredWords.every(word => lowerTitle.includes(word));

  // Проверка исключаемых слов
  const hasExcludedWords = excludedWords.length > 0 && excludedWords.some(word => lowerTitle.includes(word));

  return hasRequiredWords && !hasExcludedWords;
};

/**
 * Преобразует строку в массив слов.
 *
 * @param str - строка для преобразования.
 * @returns массив слов, приведённых к нижнему регистру.
 */
const getWordsArray = (str: string) => {
  if (!str) return [];

  try {
    // Основной вариант с поддержкой всех языков (немецкий, китайский, русский и др.)
    return Array.from(new Set(str.toLowerCase().match(/[\p{L}\p{N}'-]+/gu) || []));
  } catch (error) {
    // Альтернативный вариант для старых окружений (без \p{L})
    return Array.from(new Set(str.toLowerCase().match(/[a-zA-Zа-яА-ЯёЁäöüß汉字一-龥0-9'-]+/g) || []));
  }
};

/**
 * Обрезает заголовок до указанной длины, добавляя многоточие, если строка превышает лимит.
 *
 * @param title - заголовок для обрезки.
 * @param maxLength - максимальная длина строки (по умолчанию 50).
 * @returns обрезанный заголовок с добавлением многоточия, если длина превышает maxLength.
 */
export const truncateTitle = (title: string, maxLength: number = 50): string => {
  if (title.length > maxLength) {
    return title.slice(0, maxLength) + '...';
  }
  return title;
};

/**
 * Разбивает строку на слова, удаляет предлоги и короткие слова.
 *
 * @param text - текст для обработки.
 * @returns массив уникальных значимых слов.
 */
const extractUniqueWords = (text: string) => {
  if (!text) return [];
  
  return text
    .toLowerCase()
    .replace(/[^\p{L}\p{N}\s-]/gu, ' ') // Оставляем только буквы, цифры, пробелы, '-'
    .split(/\s+/) // Разбиваем по пробелам
    .filter(word => word.length >= 2 && !stopWords.has(word)); // Фильтруем короткие слова и стоп-слова
};

/**
 * Фильтрует элементы `data.items`, чтобы вернуть только те, у которых `id` совпадает с `selectedIds`.
 *
 * @param dataItems - массив данных `data.items`.
 * @param selectedIds - массив `id` для фильтрации.
 * @returns массив отфильтрованных элементов.
 */
const filteredDataChartItems = (dataItems: any[], selectedIds: number[]) => {
  return dataItems.filter(item => selectedIds.includes(item.id));
};

/**
 * Извлекает уникальные слова из заголовков отфильтрованных элементов `data.items`.
 *
 * @param dataItems - массив данных `data.items`.
 * @param selectedIds - массив `id` для фильтрации.
 * @returns массив уникальных значимых слов из заголовков.
 */
const titlesFromDataChart = (dataItems: any[], selectedIds: number[]) => {
  return filteredDataChartItems(dataItems, selectedIds).flatMap(item => extractUniqueWords(item.title));
};

/**
 * Собирает все значимые слова из `filteredItemsByWords`, исключая элементы с `item_id` и заголовками из `filteredDataChartItems`.
 * Затем возвращает 75% самых популярных слов по частоте встречаемости.
 *
 * @param filteredItemsByWords - массив отфильтрованных по значимости элементов из `itemStore.items`.
 * @param dataItems - массив данных `data.items`.
 * @param selectedIds - массив `id`, используемый для фильтрации `data.items`.
 * @returns массив из 75% наиболее частотных значимых слов.
 */
const mostFrequentWordsFromItems = (filteredItemsByWords: any[], dataItems: any[], selectedIds: number[]) => {
   
   // Исключаем элементы, присутствующие в `filteredDataChartItems`
   const filteredItems = filteredItemsByWords.filter(item => 
     !filteredDataChartItems(dataItems, selectedIds).some(dcItem => 
       ('item_id' in dcItem ? dcItem.item_id === item.id : dcItem.id === item.id) || 
       dcItem.title === item.title
     )
   );
    
   // Собираем все значимые слова из заголовков `filteredItems`
   const allWords = filteredItems.flatMap(item => extractUniqueWords(item.title));
   const wordFrequency: Record<string, number> = {};
 
   // Подсчитываем частоту встречаемости каждого слова
   allWords.forEach(word => {
     wordFrequency[word] = (wordFrequency[word] || 0) + 1;
   });
 
   // Рассчитываем количество слов, составляющее 75% от всех уникальных слов
   const top75PercentCount = Math.ceil(Object.keys(wordFrequency).length * 0.75);
 
   // Сортируем слова по частоте и возвращаем верхние 75%
   const sortedWords = Object.entries(wordFrequency)
     .sort((a, b) => b[1] - a[1])
     .slice(0, top75PercentCount)
     .map(([word]) => word);
   
   return sortedWords;
 };

/**
 * Находит объект `dataSearch`, соответствующий ID поискового товара `searchId`.
 *
 * @param dataSearchs - массив данных `dataStore.searchs`.
 * @param searchId - ID поискового товара, по которому производится поиск.
 * @returns объект `dataSearch`, если он найден, или `null`, если не найден.
 */
export const dataStoreSearchs = (dataSearchs: any[], searchId: number) => {
  const itemIndex = dataSearchs.findIndex(item => {
    return Number(item.id) === searchId;
  });
  
  return itemIndex !== -1 ? dataSearchs[itemIndex] : null;
};

/**
 * Находит уникальные слова из `data.items`, которых нет среди наиболее частотных значимых слов `itemStore.items`.
 * Также возвращает три самых популярных слова среди значимых слов `itemStore.items`.
 *
 * @param dataItems - массив данных `data.items`.
 * @param selectedIds - массив `id` для фильтрации `data.items`.
 * @param dataStore - объект данных `itemStore`, содержащий `items` и `searchs`.
 * @param searchId - ID поискового товара, используемого в `dataStore.searchs`.
 * @returns объект с массивом обязательных слов (`required_words`) и исключённых слов (`excluded_words`).
 */
export const uniqueWordsInDataChartNotInItems = (dataItems: any[], selectedIds: number[], dataStore: any[], searchId: number) => {
  // Извлекаем все значимые слова из `data.items`, отфильтрованных по `selectedIds`
  const dataChartWords = titlesFromDataChart(dataItems, selectedIds);
  
  // Ищем нужный элемент `dataSearch` в `dataStore.searchs` по ID поискового товара
  const dataSearch = dataStoreSearchs(dataStore.searchs, searchId);
  
  // Фильтруем `itemStore.items` по `requiredWords` и `excludedWords`, определенным в `dataSearch`
  const filteredItemsByWords = dataStore.items.filter(item => {
    // Формируем текст для поиска
    const searchText = `${item.brand ? item.brand + ' ' : ''}${item.title}`.trim();
    
    // Получает слова из групп
    const groups = (() => {
      const group = dataStore.groups?.find(group => group.id === item.group);
      return {
        required_words: group?.required_words || "",
        excluded_words: group?.excluded_words || "",
      };
    })();
    
    // Проверяем, что dataSearch не null и содержит обязательные свойства
    return dataSearch && filterByWords(dataSearch, searchText, groups);
  });

  // Получаем 75% наиболее частотных значимых слов из отфильтрованных `itemStore.items`
  const itemStoreFrequentWords = mostFrequentWordsFromItems(filteredItemsByWords, dataItems, selectedIds);
  
  // Извлекаем первые три наиболее частотных слова из `itemStoreFrequentWords`
  const firstThreeWords = itemStoreFrequentWords.slice(0, Math.min(3, itemStoreFrequentWords.length));
  
  // Создаем массив обязательных слов, которые есть в `firstThreeWords`, но отсутствуют в `dataChartWords` и `dataSearchs.required_words`
  const requiredWords = firstThreeWords.filter(
    word => !dataChartWords.includes(word) && !(dataSearch?.required_words || []).includes(word)
  );

  // Создаем массив исключённых слов, которые есть в `dataChartWords`, но отсутствуют в `itemStoreFrequentWords` и `dataSearchs.excluded_words`
  let excludedWords = dataChartWords.filter(
    word => !itemStoreFrequentWords.includes(word) && !(dataSearch?.excluded_words || []).includes(word)
  );
  
  // Оставляем только уникальные слова в `excluded_words`
  excludedWords = Array.from(new Set(excludedWords));

  // Возвращаем объект с массивами обязательных и исключённых слов
  return {
    'required_words': requiredWords,
    'excluded_words': excludedWords,
  };
};
